import { Injectable } from '@angular/core';
import { VexLayoutService } from '@vex/services/vex-layout.service';
import { NavigationItem } from './navigation-item.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from '../../shared/services/auth.service';
import { EmployeeService } from '../../shared/services/employee.service';
import { Helper } from '../../shared/constants/helper';
import { PermissionConstant } from '../../shared/constants/permission-constant';

@Injectable({
  providedIn: 'root'
})
export class NavigationLoaderService {
  user: any;
  userData: any;
  private readonly _items: BehaviorSubject<NavigationItem[]> =
    new BehaviorSubject<NavigationItem[]>([]);

  get items$(): Observable<NavigationItem[]> {
    return this._items.asObservable();
  }

  constructor(
    private readonly layoutService: VexLayoutService,
    private authService: AuthService,
    private employeeService: EmployeeService,
  ) {
    const self = localStorage.getItem('self');
    if(self){
      this.authService.self().subscribe((data) => {
        if (data && data.id) {
          this.user = data;
          if (this.user && this.user['user_type'] === 'EMPLOYEE') {
            this.checkUserDetails();
          }else {
            this.loadNavigation();
          }
        }
      });
    }else {
      this.loadNavigation();
    }
  }

  loadNavigation(): void {
    this._items.next([
      {
        type: 'subheading',
        label: 'Dashboard',
        availableTo: [PermissionConstant.ALL],
        is_hidden: false,
        children: [
          {
            type: 'link',
            label: 'Home',
            route: '/home',
            icon: 'mat:dashboard',
            routerLinkActiveOptions: { exact: true },
            availableTo: [PermissionConstant.ALL],
            is_hidden: false
          }
        ]
      },
      {
        type: 'subheading',
        label: 'General',
        availableTo: [PermissionConstant.GENERAL],
        is_hidden: false,
        children: [
          {
            type: 'dropdown',
            label: 'Admins',
            icon: 'mat:admin_panel_settings',
            availableTo: [PermissionConstant.ADMIN],
            is_hidden: (this.user && this.user.user_type && this.user.user_type != 'SUPER_ADMIN'),
            children: [
              {
                type: 'link',
                label: 'Add',
                route: '/general/admin/add',
                availableTo: [PermissionConstant.ADMIN_ADD],
                is_hidden: (this.user && this.user.user_type && this.user.user_type != 'SUPER_ADMIN'),
              },
              {
                type: 'link',
                label: 'List',
                route: '/general/admin/list',
                availableTo: [PermissionConstant.ADMIN_LIST],
                is_hidden: (this.user && this.user.user_type && this.user.user_type != 'SUPER_ADMIN')
              }
            ]
          },
          {
            type: 'dropdown',
            label: 'Centers',
            icon: 'mat:admin_panel_settings',
            availableTo: [PermissionConstant.CENTER],
            is_hidden: false,
            children: [
              {
                type: 'link',
                label: 'Add',
                route: '/general/center/add',
                availableTo: [PermissionConstant.CENTER_ADD],
                is_hidden: false
              },
              {
                type: 'link',
                label: 'List',
                route: '/general/center/list',
                availableTo: [PermissionConstant.CENTER_LIST],
                is_hidden: false
              }
            ]
          },
          {
            type: 'dropdown',
            label: 'Sessions',
            icon: 'mat:access_time',
            availableTo: [PermissionConstant.SESSION],
            is_hidden: false,
            children: [
              {
                type: 'link',
                label: 'Add',
                route: '/general/session/add',
                availableTo: [PermissionConstant.SESSION_ADD],
                is_hidden: false
              },
              {
                type: 'link',
                label: 'List',
                route: '/general/session/list',
                availableTo: [PermissionConstant.SESSION_LIST],
                is_hidden: false
              }
            ]
          },
          {
            type: 'dropdown',
            label: 'Meetings',
            icon: 'mat:people',
            availableTo: [PermissionConstant.MEETINGS],
            is_hidden: false,
            children: [
              {
                type: 'link',
                label: 'Persons',
                route: '/general/meetings/persons',
                availableTo: [PermissionConstant.MEETING_PERSON],
                is_hidden: (!(this.user && this.user.user_type != "EMPLOYEE"))
              },
              {
                type: 'link',
                label: 'Schedule',
                route: '/general/meetings/schedule',
                availableTo: [PermissionConstant.MEETING_SCHEDULE],
                is_hidden: false
              }
            ]
          },
          {
            type: 'link',
            label: 'Report',
            route: '/general/reports',
            icon: 'mat:dashboard_customize',
            routerLinkActiveOptions: { exact: true },
            availableTo: [PermissionConstant.GENERAL_REPORT],
            is_hidden: false
          },
        ]
      },
      {
        type: 'subheading',
        label: 'Employees',
        availableTo: [PermissionConstant.EMPLOYEE],
        is_hidden: false,
        children: [
          {
            type: 'link',
            label: 'Departments',
            route: '/employees/department',
            icon: 'mat:manage_accounts',
            availableTo: [PermissionConstant.DEPARTMENT],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Designations',
            route: '/employees/designation',
            icon: 'mat:manage_accounts',
            availableTo: [PermissionConstant.DESIGNATION],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Add Employee',
            route: '/employees/employee/add',
            icon: 'mat:manage_accounts',
            availableTo: [PermissionConstant.EMPLOYEE_ADD],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'List Employee',
            route: '/employees/employee/list',
            icon: 'mat:manage_accounts',
            availableTo: [PermissionConstant.EMPLOYEE_LIST],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Attendance',
            route: '/employees/employee/attendance',
            icon: 'mat:manage_accounts',
            availableTo: [PermissionConstant.EMPLOYEE_ATTENDANCE],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Leave',
            route: '/employees/leave',
            icon: 'mat:flip_to_back',
            availableTo: ['EMPLOYEE'],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Resignation',
            route: '/employees/employee/resignation',
            icon: 'mat:indeterminate_check_box',
            availableTo: [PermissionConstant.EMPLOYEE_RESIGNATION],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Payroll',
            route: '/employees/employee/payroll',
            icon: 'mat:manage_accounts',
            availableTo: [PermissionConstant.EMPLOYEE_PAYROLL],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Permissions',
            route: '/employees/permission',
            icon: 'mat:key',
            availableTo: [PermissionConstant.PERMISSION],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Report',
            route: '/employees/reports',
            icon: 'mat:dashboard_customize',
            routerLinkActiveOptions: { exact: true },
            availableTo: [PermissionConstant.EMPLOYEE_REPORT],
            is_hidden: false
          }
        ]
      },
      {
        type: 'subheading',
        label: 'Modules',
        availableTo: [PermissionConstant.MODULE],
        is_hidden: false,
        children: [
          {
            type: 'link',
            label: 'Schools',
            route: '/modules/school',
            icon: 'mat:school',
            availableTo: [PermissionConstant.SCHOOL],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Programs',
            route: '/modules/program',
            icon: 'mat:cast_for_education',
            availableTo: [PermissionConstant.PROGRAM],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Courses',
            route: '/modules/course',
            icon: 'mat:how_to_vote',
            availableTo: [PermissionConstant.COURSE],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Branches',
            route: '/modules/branches',
            icon: 'mat:spa',
            availableTo: [PermissionConstant.BRANCH],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Mappings',
            route: '/modules/mapping',
            icon: 'mat:pin_invoke',
            availableTo: [PermissionConstant.MAPPING],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Subjects',
            route: '/modules/subject',
            icon: 'mat:book',
            availableTo: [PermissionConstant.SUBJECT],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Reports',
            route: '/modules/report',
            icon: 'mat:dashboard_customize',
            availableTo: [PermissionConstant.MODULE_REPORT],
            is_hidden: false
          }
        ]
      },
      {
        type: 'subheading',
        label: 'Inventorys',
        availableTo: [PermissionConstant.INVENTORY],
        is_hidden: false,
        children: [
          {
            type: 'link',
            label: 'Categories',
            route: '/inventory/category',
            icon: 'mat:category',
            availableTo: [PermissionConstant.INVENTORY_CATEGORY],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'List',
            route: '/inventory/list',
            icon: 'mat:inventory',
            availableTo: [PermissionConstant.INVENTORY_LIST],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Transactions',
            route: '/inventory/transaction',
            icon: 'mat:receipt',
            availableTo: [PermissionConstant.INVENTORY_TRANSACTION],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Reports',
            route: '/inventory/report',
            icon: 'mat:dashboard_customize',
            availableTo: [PermissionConstant.INVENTORY_REPORT],
            is_hidden: false
          }
        ]
      },
      {
        type: 'subheading',
        label: 'Books',
        availableTo: [PermissionConstant.BOOK],
        is_hidden: false,
        children: [
          {
            type: 'link',
            label: 'Categories',
            route: '/book/category',
            icon: 'mat:category',
            availableTo: [PermissionConstant.BOOK_CATEGORY],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'List',
            route: '/book/list',
            icon: 'mat:inventory',
            availableTo: [PermissionConstant.BOOK_LIST],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Suppliers',
            route: '/book/suppliers',
            icon: 'mat:transfer_within_a_station',
            availableTo: [PermissionConstant.BOOK_LIST],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Transactions',
            route: '/book/transaction',
            icon: 'mat:receipt',
            availableTo: [PermissionConstant.BOOK_TRANSACTION],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Settings',
            route: '/book/late-fee',
            icon: 'mat:build',
            availableTo: [PermissionConstant.BOOK_TRANSACTION],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Reports',
            route: '/book/report',
            icon: 'mat:dashboard_customize',
            availableTo: [PermissionConstant.BOOK_REPORT],
            is_hidden: false
          }
        ]
      },
      {
        type: 'subheading',
        label: 'Libraries',
        availableTo: [PermissionConstant.LIBRARY],
        is_hidden: false,
        children: [
          {
            type: 'link',
            label: 'List',
            route: '/library/list',
            icon: 'mat:local_library',
            availableTo: [PermissionConstant.LIBRARY_BORROWING],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Borrowings',
            route: '/library/borrowing',
            icon: 'mat:library_books',
            availableTo: [PermissionConstant.LIBRARY_BORROWING],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Transactions',
            route: '/library/transaction',
            icon: 'mat:receipt',
            availableTo: [PermissionConstant.LIBRARY_TRANSACTION],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Reports',
            route: '/library/report',
            icon: 'mat:dashboard_customize',
            availableTo: [PermissionConstant.LIBRARY_REPORT],
            is_hidden: false
          }
        ]
      },
      {
        type: 'subheading',
        label: 'Students',
        availableTo: [PermissionConstant.STUDENT],
        is_hidden: false,
        children: [
          {
            type: 'link',
            label: 'Add',
            route: '/student/add',
            icon: 'mat:person_add',
            availableTo: [PermissionConstant.STUDENT_ADD],
            is_hidden: (!(this.user && this.user.user_type == "EMPLOYEE"))
          },
          {
            type: 'link',
            label: 'Direct Students',
            route: '/student/direct-student',
            icon: 'mat:diversity_3',
            availableTo: [PermissionConstant.STUDENT_LIST],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Verify Direct Students',
            route: '/student/verify-direct-student',
            icon: 'mat:diversity_3',
            availableTo: [PermissionConstant.STUDENT_VERIFY],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Center Students',
            route: '/student/center-student',
            icon: 'mat:diversity_3',
            availableTo: [PermissionConstant.STUDENT_CENTER],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Verify Center Students',
            route: '/student/verify-center-student',
            icon: 'mat:diversity_3',
            availableTo: [PermissionConstant.STUDENT_VERIFY_CENTER],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Direct Student Transactions',
            route: '/student/transactions',
            icon: 'mat:receipt',
            availableTo: [PermissionConstant.STUDENT_TRANSACTION],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Verify Scholarships',
            route: '/student/verify-scholarship',
            icon: 'mat:diversity_3',
            availableTo: [PermissionConstant.STUDENT_SCHOLARSHIP],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Attendance',
            route: '/student/attendance',
            icon: 'mat:pending_actions',
            availableTo: [PermissionConstant.STUDENT_ATTENDANCE],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Enrollment',
            route: '/student/enrollment',
            icon: 'mat:diversity_3',
            availableTo: [PermissionConstant.STUDENT_ENROLLMENT],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Final Student List',
            route: '/student/final-student-list',
            icon: 'mat:diversity_3',
            availableTo: [PermissionConstant.FINAL_STUDENT_LIST],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Final Verification',
            route: '/student/finalised-student-list',
            icon: 'mat:diversity_3',
            availableTo: [PermissionConstant.FINALISED_STUDENT_LIST],
            is_hidden: (this.user && this.user.user_type && this.user.user_type != 'SUPER_ADMIN'),
          },
          {
            type: 'link',
            label: 'Progress Manager',
            route: '/student/progress-manager',
            icon: 'mat:assignment',
            availableTo: [PermissionConstant.STUDENT_ENROLLMENT],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Hostel Students List',
            route: '/student/hostel-students',
            icon: 'mat:gite',
            availableTo: [PermissionConstant.HOSTEL_STUDENT_LIST],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Reports',
            route: '/student/report',
            icon: 'mat:dashboard_customize',
            availableTo: [PermissionConstant.STUDENT_REPORT],
            is_hidden: false
          },
        ]
      },
      {
        type: 'subheading',
        label: 'BDE',
        availableTo: [PermissionConstant.BDE],
        is_hidden: false,
        children: [
          {
            type: 'link',
            label: 'Add',
            route: '/bde/add',
            icon: 'mat:add_circle',
            availableTo: [PermissionConstant.BDE_ADD],
            is_hidden:  (this.user && this.user.user_type && this.user.user_type === 'SUPER_ADMIN'),
          },
          {
            type: 'link',
            label: 'List',
            route: '/bde/list',
            icon: 'mat:view_list',
            availableTo: [PermissionConstant.BDE_LIST],
            is_hidden: false
          },
        ]
      },
      {
        type: 'subheading',
        label: 'Accounts',
        availableTo: [PermissionConstant.ACCOUNT_ACCOUNT],
        is_hidden: false,
        children: [
          {
            type: 'link',
            label: 'Receipt',
            route: '/account/receipts',
            icon: 'mat:credit_card',
            availableTo: [PermissionConstant.ACCOUNT_RECEIPT],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Receipt Transaction',
            route: '/account/receipt-transactions',
            icon: 'mat:credit_card',
            availableTo: [PermissionConstant.ACCOUNT_RECEIPT_TRANSACTION],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Wallet Transaction',
            route: '/account/wallet-transactions',
            icon: 'mat:account_balance_wallet',
            availableTo: [PermissionConstant.ACCOUNT_WALLET_TRANSACTION],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Reports',
            route: '/account/report',
            icon: 'mat:dashboard_customize',
            availableTo: [PermissionConstant.ACCOUNT_REPORT],
            is_hidden: false
          }
        ]
      },
      {
        type: 'subheading',
        label: 'Exams',
        availableTo: [PermissionConstant.EXAMS],
        is_hidden: false,
        children: [
          {
            type: 'dropdown',
            label: 'Internal',
            icon: 'mat:admin_panel_settings',
            availableTo: [PermissionConstant.INTERNAL],
            is_hidden: false,
            children: [
              {
                type: 'link',
                label: 'Sessions',
                route: '/exams/internal/session',
                icon: 'mat:dashboard_customize',
                availableTo: [PermissionConstant.INTERNAL_EXAM_SESSION],
                is_hidden: false
              },
              {
                type: 'link',
                label: 'Questions',
                route: '/exams/internal/questions',
                icon: 'mat:dashboard_customize',
                availableTo: [PermissionConstant.INTERNAL_EXAM_QUESTIONS],
                is_hidden: false
              },
              {
                type: 'link',
                label: 'Scheduler',
                route: '/exams/internal/scheduler',
                icon: 'mat:dashboard_customize',
                availableTo: [PermissionConstant.INTERNAL_EXAM_SCHEDULER],
                is_hidden: false
              },
              {
                type: 'link',
                label: 'Halls',
                route: '/exams/internal/halls',
                icon: 'mat:dashboard_customize',
                availableTo: [PermissionConstant.INTERNAL_EXAM_HALLS],
                is_hidden: false
              },
              {
                type: 'link',
                label: 'Result',
                route: '/exams/internal/result',
                icon: 'mat:dashboard_customize',
                availableTo: [PermissionConstant.INTERNAL_EXAM_RESULT],
                is_hidden: false
              }
            ]
          },
          {
            type: 'dropdown',
            label: 'Final',
            icon: 'mat:admin_panel_settings',
            availableTo: [PermissionConstant.FINAL],
            is_hidden: false,
            children: [
              {
                type: 'link',
                label: 'Sessions',
                route: '/exams/final/session',
                icon: 'mat:dashboard_customize',
                availableTo: [PermissionConstant.FINAL_EXAM_SESSION],
                is_hidden: false
              },
              {
                type: 'link',
                label: 'Questions',
                route: '/exams/final/questions',
                icon: 'mat:dashboard_customize',
                availableTo: [PermissionConstant.FINAL_EXAM_QUESTIONS],
                is_hidden: false
              },
              {
                type: 'link',
                label: 'Eligible Students',
                route: '/exams/final/eligible-students',
                icon: 'mat:dashboard_customize',
                availableTo: [PermissionConstant.FINAL_EXAM_ELIGIBLE_STUDENTS],
                is_hidden: false
              },
              {
                type: 'link',
                label: 'Scheduler',
                route: '/exams/final/scheduler',
                icon: 'mat:dashboard_customize',
                availableTo: [PermissionConstant.FINAL_EXAM_SCHEDULER],
                is_hidden: false
              },
              {
                type: 'link',
                label: 'Halls',
                route: '/exams/final/halls',
                icon: 'mat:dashboard_customize',
                availableTo: [PermissionConstant.FINAL_EXAM_HALLS],
                is_hidden: false
              },
              {
                type: 'link',
                label: 'Result',
                route: '/exams/final/result',
                icon: 'mat:dashboard_customize',
                availableTo: [PermissionConstant.FINAL_EXAM_RESULT],
                is_hidden: false
              }
            ]
          },
        ]
      },
      {
        type: 'subheading',
        label: 'DOCS',
        availableTo: [PermissionConstant.DOCS],
        is_hidden: false,
        children: [
          {
            type: 'link',
            label: 'Marksheet',
            route: '/docs/marksheet',
            icon: 'mat:directions_car',
            availableTo: [PermissionConstant.DOCS_MARKSHEET],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Transcript',
            route: '/docs/transcript',
            icon: 'mat:holiday_village',
            availableTo: [PermissionConstant.DOCS_TRANSCRIPT],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Original Degree',
            route: '/docs/original-degree',
            icon: 'mat:dashboard_customize',
            availableTo: [PermissionConstant.DOCS_ORIGINAL_DEGREE],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'No Dues Slip',
            route: '/docs/no-dues-slip',
            icon: 'mat:file_copy',
            availableTo: [PermissionConstant.DOCS_NO_DUES_SLIP],
            is_hidden: false
          }
        ]
      },
      {
        type: 'subheading',
        label: 'Utilities',
        availableTo: [PermissionConstant.UTILITIES],
        is_hidden: false,
        children: [
          {
            type: 'link',
            label: 'Hostels',
            route: '/utilities/hostel',
            icon: 'mat:gite',
            availableTo: [PermissionConstant.UTILITIES_HOSTEL],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Vehicles',
            route: '/utilities/vehicle',
            icon: 'mat:directions_car',
            availableTo: [PermissionConstant.UTILITIES_VEHICLE],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Holidays',
            route: '/utilities/holiday',
            icon: 'mat:holiday_village',
            availableTo: [PermissionConstant.UTILITIES_HOLIDAY],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Miscellaneous',
            route: '/utilities/miscellaneous-charge',
            icon: 'mat:receipt',
            availableTo: [PermissionConstant.UTILITIES_HOLIDAY],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Scholarship',
            route: '/utilities/scholarship',
            icon: 'mat:receipt',
            availableTo: [PermissionConstant.UTILITIES_HOLIDAY],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Reports',
            route: '/utilities/report',
            icon: 'mat:dashboard_customize',
            availableTo: [PermissionConstant.UTILITIES_REPORT],
            is_hidden: false
          }
        ]
      },
      {
        type: 'subheading',
        label: 'Alumnis',
        availableTo: [PermissionConstant.ALUMNI],
        is_hidden: false,
        children: [
          {
            type: 'link',
            label: 'List',
            route: '/alumni/list',
            icon: 'mat:person',
            availableTo: [PermissionConstant.ALUMNI_LIST],
            is_hidden: false
          }
        ]
      },
      {
        type: 'subheading',
        label: 'Support Tickets',
        availableTo: [PermissionConstant.SUPPORT_TICKET],
        is_hidden: false,
        children: [
          {
            type: 'link',
            label: 'Student\'s Ticket',
            route: '/support-ticket/student-support-ticket',
            icon: 'mat:confirmation_number',
            availableTo: [PermissionConstant.STUDENT_SUPPORT_TICKET],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Center\'s Ticket',
            route: '/support-ticket/center-support-ticket',
            icon: 'mat:confirmation_number',
            availableTo: [PermissionConstant.CENTER_SUPPORT_TICKET],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Report',
            route: '/support-ticket/report',
            icon: 'mat:dashboard_customize',
            availableTo: [PermissionConstant.SUPPORT_TICKET_REPORT],
            is_hidden: false
          }
        ]
      },
      {
        type: 'subheading',
        label: 'Allegations',
        availableTo: [PermissionConstant.ALLEGATIONS],
        is_hidden: false,
        children: [
          {
            type: 'link',
            label: 'Add',
            route: '/allegations/add',
            icon: 'mat:add_circle',
            availableTo: [PermissionConstant.ADD_ALLEGATIONS],
            is_hidden: (this.user && this.user.user_type && this.user.user_type === 'SUPER_ADMIN'),
          },
          {
            type: 'link',
            label: 'List',
            route: '/allegations/list',
            icon: 'mat:view_list',
            availableTo: [PermissionConstant.LIST_ALLEGATIONS],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Transactions',
            route: '/allegations/transactions',
            icon: 'mat:credit_card',
            availableTo: [PermissionConstant.ALLEGATION_TRANSACTIONS],
            is_hidden: false
          },
        ]
      },
      {
        type: 'subheading',
        label: 'Tasks',
        availableTo: [PermissionConstant.ALL],
        is_hidden: false,
        children: [
          {
            type: 'link',
            label: 'List',
            route: '/tasks/list',
            icon: 'mat:add_circle',
            availableTo: [PermissionConstant.ALL],
            is_hidden: false
          },
        ]
      },
      {
        type: 'subheading',
        label: 'Notifications',
        availableTo: [PermissionConstant.NOTIFICATION],
        is_hidden: false,
        children: [
          {
            type: 'link',
            label: 'Student\'s Notification',
            route: '/notification/student-notification',
            icon: 'mat:notifications',
            availableTo: [PermissionConstant.STUDENT_NOTIFICATION],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Center\'s Notification',
            route: '/notification/center-notification',
            icon: 'mat:notifications',
            availableTo: [PermissionConstant.CENTER_NOTIFICATION],
            is_hidden: false
          },
          {
            type: 'link',
            label: 'Report',
            route: '/notification/report',
            icon: 'mat:dashboard_customize',
            availableTo: [PermissionConstant.NOTIFICATION_REPORT],
            is_hidden: false
          }
        ]
      }
    ]);
  }

  getSelfDetails() {
    this.authService.self().subscribe((data) => {
      if (data && data.id) {
        this.user = data;
        if (this.user && this.user['user_type'] === 'EMPLOYEE') {
          this.checkUserDetails();
        }else {
          this.loadNavigation();
        }
      }
    });
  }

  checkUserDetails() {
    this.employeeService.detail(this.user.unique_id).subscribe((data) => {
      if (data && data['employee_details'] && data['employee_details']['designation'] && data['employee_details']['designation']['designation_permissions']) {
        this.userData = data['employee_details']['designation']['designation_permissions'];
        let rolePermissions = [];
        if (this.userData && this.userData.length > 0) {
          this.userData.forEach(subPermission => {
            if (subPermission && subPermission['sub_permission'] && subPermission['sub_permission']['meta']) {
              rolePermissions.push(subPermission['sub_permission']['meta']);
            }
          });
        }
        this.loadNavigation();
        this._items.forEach(menu => {
          menu.forEach((item) => {
            if(item && item['availableTo'] && !item['availableTo'].includes('ALL')) {
              item['is_hidden'] = !Helper.findCommonElements(item['availableTo'], rolePermissions);
              if (item['children'] && item['children'].length > 0) {
                item['children'].forEach(child => {
                  child['is_hidden'] = !Helper.findCommonElements(child.availableTo, rolePermissions);
                });
              }
            } else {
              item['is_hidden'] = false;
            }
          });
        });
      }
    });
  }
}
