<app-header></app-header>
<div class="container-fluid text-center p-6">
  <div *ngIf="meeting" class="md:w-[40%] m-auto font-medium rounded p-6" [ngClass]="
  meeting?.status === 'PENDING' ? 'bg-blue-300' :
    meeting?.status === 'APPROVED' ? 'bg-pink-300' :
    (meeting?.status === 'ENDED' || meeting?.status === 'REJECTED') && !isExpired ? 'bg-yellow-200' :
    isExpired ? 'bg-red-800 text-white': ''
  ">
    <div class="flex justify-end" *ngIf="meeting?.status === 'ENDED' || meeting?.status === 'REJECTED'">
      <div class="px-2 py-1 font-medium rounded"
           [ngClass]="isExpired ? 'bg-white text-red-800' : 'bg-red-800 text-white'">{{ remainingTime ? 'Expiring in: ' + remainingTime : isExpired ? 'Expired !' : '' }}
      </div>
    </div>
    <div class="flex">

      <div class="w-1/2">
        @if (meeting?.file?.partial_url) {
          <img [src]="environment.s3_bucket_url+meeting?.file?.partial_url" class="w-[80%] h-auto rounded" alt="img">
        } @else {
          <img src="/assets/images/visitor-image-icon.png" class="w-[80%] h-auto rounded" alt="img">
        }
      </div>
      <div class="my-auto text-[24px] md:text-[32px] w-2/3">
        {{ meeting?.name }}
        <div class="text-[16px] py-3">{{ meeting?.visitor_type | titlecase }}</div>
      </div>
    </div>
    <div class="flex mt-3 py-2">
      <div class="text-start w-1/3">
        Meeting Id
      </div>
      <div class="text-start w-1/3">
        :
      </div>
      <div class="text-start w-1/3">
        {{ meeting?.meeting_id }}
      </div>
    </div>
    <div class="flex py-2">
      <div class="text-start w-1/3">
        Meeting Person
      </div>
      <div class="text-start w-1/3">
        :
      </div>
      <div class="text-start w-1/3">
        {{ meeting?.meeting_person?.employee?.name }}
      </div>
    </div>
    <div class="flex py-2">
      <div class="text-start w-1/3">
        Department
      </div>
      <div class="text-start w-1/3">
        :
      </div>
      <div class="text-start w-1/3">
        {{ meeting?.meeting_person?.department?.name }}
      </div>
    </div>
    <div class="flex py-2">
      <div class="text-start w-1/3">
        Status
      </div>
      <div class="text-start w-1/3">
        :
      </div>
      <div class="text-start w-1/3">
        {{ meeting?.status }}
      </div>
    </div>
    <div class="flex py-2">
      <div class="text-start w-1/3">
        Start time
      </div>
      <div class="text-start w-1/3">
        :
      </div>
      <div class="text-start w-1/3">
        {{ (meeting?.meeting_start_time | date : 'shortTime') || '-' }}
      </div>
    </div>
    <div class="flex py-2">
      <div class="text-start w-1/3">
        End time
      </div>
      <div class="text-start w-1/3">
        :
      </div>
      <div class="text-start w-1/3">
        {{ (meeting?.meeting_end_time | date : 'shortTime') || '-' }}
      </div>
    </div>
    <div class="flex py-2" *ngIf="meeting?.persons && JSON.parse(meeting?.persons).length > 0">
      <div class="text-start w-1/3">
        Persons
      </div>
      <div class="text-start w-1/3">
        :
      </div>
      <div class="text-start w-1/3">
        <span *ngFor="let person of JSON.parse(meeting?.persons); let i = index">{{ person?.name }}<span
          *ngIf="JSON.parse(meeting?.persons).length > 1 && i !== JSON.parse(meeting?.persons).length-1"
          class="mr-1">,</span></span>
      </div>
    </div>

  </div>
</div>
