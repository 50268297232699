import { Injectable } from '@angular/core';
import {BaseService} from "./base.service";
import {HttpClient} from "@angular/common/http";
import {AlertService} from "./alert.service";
import {GlobalService} from "./global.service";
import {Observable} from "rxjs";
import {AppUrl} from "../constants/app-url";

@Injectable({
  providedIn: 'root'
})
export class MeetingsService extends BaseService{

  constructor(public http: HttpClient,
              public alertService: AlertService,
              public globalService: GlobalService) {
    super(http, alertService, globalService);
  }

  add(data: {}): Observable<any>{
    return this.postRequest(AppUrl.MEETINGS(), data);
  }

  get(data: {}): Observable<any> {
    return this.getRequestWithoutErrorMessage(AppUrl.MEETINGS(), data);
  }

  update(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.MEETINGS(uniqueId), data);
  }

  status(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.MEETINGS_STATUS(uniqueId), data);
  }

  delete(uniqueId: any): Observable<any> {
    return this.deleteRequest(AppUrl.MEETINGS(uniqueId));
  }

  addMeeting(data: any): Observable<any> {
    return this.postRequest(AppUrl.SCHEDULE_MEETING(), data);
  }

  updateMeeting(data: any, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.SCHEDULE_MEETING(uniqueId), data);
  }

  deleteMeeting(uniqueId: any): Observable<any> {
    return this.deleteRequest(AppUrl.SCHEDULE_MEETING(uniqueId));
  }

  getMeetings(data: any = {}): Observable<any> {
    return this.getRequestWithoutErrorMessage(AppUrl.SCHEDULE_MEETING(), data);
  }

  detail(id: any): Observable<any> {
    return this.getRequestWithoutErrorMessage(AppUrl.SCHEDULE_MEETING(id));
  }

  updateMeetingApprovalStatus(data: any, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.MEETING_APPROVAL_STATUS(uniqueId), data);
  }

  visitorToken(id: any): Observable<any> {
    return this.getRequest(AppUrl.VISITOR_TOKEN(id))
  }
}
