import { Injectable } from '@angular/core';

@Injectable()
export class AppConstants {
  static SYSTEM_ENTRY_START_YEAR = 2019;
  static RE_REGISTRATION_ENTRY_START_YEAR = 2014;

  static INSTITUTION_TYPES = [
    { value: 'UNIVERSITY', option: 'University' },
    { value: 'COLLEGE', option: 'College' },
    { value: 'INSTITUTE', option: 'Institute' },
    { value: 'OTHER', option: 'Other' }
  ];

  static DEFAULT_COUNTRY = {
    id: '100',
    name: 'India'
  };

  static FIXED_STAGE_IDS = [1, 2, 3, 4, 5];
  static ADMISSION_DONE_STAGE_ID = 5;
  static FIXED_REASON_IDS = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40
  ];
  static FIXED_SR_MAPPING_IDS = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40
  ];

  static categories = [
    {
      name: 'General',
      value: 'GENERAL'
    },
    {
      name: 'OBC',
      value: 'OBC'
    },
    {
      name: 'SC',
      value: 'SC'
    },
    {
      name: 'ST',
      value: 'ST'
    },
    {
      name: 'Others',
      value: 'OTHERS'
    }
  ];

  static processStatusesAdminForListStudents = [
    {
      name: 'All Process',
      value: ''
    },
    {
      name: 'Pending',
      value: 'PENDING'
    },
    {
      name: 'Processed',
      value: 'PROCESSED_BY_EMPLOYEE'
    },
    {
      name: 'Verified',
      value: 'VERIFIED'
    },
    {
      name: 'Document Required',
      value: 'DOCUMENT_REQUIRED'
    },
    {
      name: 'Rejected',
      value: 'REJECTED'
    }
  ];

  static processStatusesHeadCounselorForListStudents: any = [
    {
      name: 'Pending',
      value: 'PENDING'
    },
    {
      name: 'Processable',
      value: 'PROCESSABLE'
    },
    {
      name: 'Processed',
      value: 'PROCESSED_BY_HEAD_COUNSELOR'
    },
    {
      name: 'Verified',
      value: 'VERIFIED'
    },
    {
      name: 'Document Required',
      value: 'DOCUMENT_REQUIRED'
    },
    {
      name: 'Rejected',
      value: 'REJECTED'
    }
  ];

  static processStatusesCounselorForProcessStudents: any = [
    {
      name: 'Processable',
      value: 'PROCESSABLE'
    },
    {
      name: 'Processed',
      value: 'PROCESSED_BY_COUNSELOR'
    },
    {
      name: 'Processed By Head Counselor',
      value: 'PROCESSED_BY_HEAD_COUNSELOR'
    },
    {
      name: 'Verified',
      value: 'VERIFIED'
    }
  ];

  static processStatusesHeadCounselorForProcessStudents: any = [
    {
      name: 'Processable',
      value: 'PROCESSABLE'
    },
    {
      name: 'Processed',
      value: 'PROCESSED_BY_HEAD_COUNSELOR'
    },
    {
      name: 'Verified',
      value: 'VERIFIED'
    }
  ];

  static processStatusesHeadCounselorCounselorStudentForProcessStudents: any = [
    {
      name: 'Processable',
      value: 'PROCESSED_BY_COUNSELOR'
    },
    {
      name: 'Processed',
      value: 'PROCESSED_BY_HEAD_COUNSELOR'
    },
    {
      name: 'Verified',
      value: 'VERIFIED'
    }
  ];

  static processStatusesAdminForProcessStudents: any = [
    {
      name: 'Not Verified',
      value: 'PROCESSED_BY_EMPLOYEE'
    },
    {
      name: 'Verified',
      value: 'VERIFIED'
    },
    {
      name: 'Document Required',
      value: 'DOCUMENT_REQUIRED'
    },
    {
      name: 'Rejected',
      value: 'REJECTED'
    }
  ];

  static processStatusesForScholarship: any = [
    {
      name: 'Pending',
      value: 'PENDING'
    },
    {
      name: 'Verified',
      value: 'VERIFIED'
    },
    {
      name: 'Rejected',
      value: 'REJECTED'
    }
  ];

  static processStatusesAdminCenterForProcessStudents: any = [
    {
      name: 'Not Verified',
      value: 'PROCESSED_BY_CENTER'
    },
    {
      name: 'Verified',
      value: 'VERIFIED'
    },
    {
      name: 'Document Required',
      value: 'DOCUMENT_REQUIRED'
    },
    {
      name: 'Rejected',
      value: 'REJECTED'
    }
  ];

  static processTypes = [
    {
      name: 'Pending',
      value: 'PENDING'
    },
    {
      name: 'Processable',
      value: 'PROCESSABLE'
    },
    {
      name: 'Processed By Head Counselor',
      value: 'PROCESSED_BY_HEAD_COUNSELOR'
    },
    {
      name: 'Processed By Counselor',
      value: 'PROCESSED_BY_COUNSELOR'
    },
    {
      name: 'Verified',
      value: 'VERIFIED'
    },
    {
      name: 'Document Required',
      value: 'DOCUMENT_REQUIRED'
    },
    {
      name: 'Rejected',
      value: 'REJECTED'
    }
  ];

  static employementStatuses = [
    {
      name: 'Employed',
      value: 'EMPLOYED'
    },
    {
      name: 'Un-Employed',
      value: 'UN_EMPLOYED'
    }
  ];

  static verificationStatues: any = [
    {
      name: 'Verified',
      value: 'VERIFIED'
    },
    {
      name: 'Rejected',
      value: 'REJECTED'
    },
    {
      name: 'Document Required',
      value: 'DOCUMENT_REQUIRED'
    }
  ];

  static filterVerficationStatuses: any = [
    {
      name: 'Verified',
      value: 'VERIFIED'
    },
    {
      name: 'Rejected',
      value: 'REJECTED'
    },
    {
      name: 'Document Required',
      value: 'DOCUMENT_REQUIRED'
    }
  ];

  static filterProcessTypes: any = [
    {
      name: 'Pending',
      value: 'PENDING'
    },
    {
      name: 'Processable',
      value: 'PROCESSABLE'
    },
    {
      name: 'Processing',
      value: 'PROCESSING_BY_CENTER'
    },
    {
      name: 'Processed',
      value: 'PROCESSED'
    }
  ];

  static universityVerificationStatues: any = [
    {
      name: 'Pending',
      value: 'PENDING'
    },
    {
      name: 'Verified',
      value: 'VERIFIED'
    },
    {
      name: 'Rejected',
      value: 'REJECTED'
    }
  ];

  static universityVerificationStatuesFilter: any = [
    {
      name: 'Verified',
      value: 'VERIFIED'
    },
    {
      name: 'Rejected',
      value: 'REJECTED'
    }
  ];

  static supportTicketStatuses: any = [
    {
      name: 'Pending',
      value: 'PENDING'
    },
    {
      name: 'Resolved',
      value: 'RESOLVED'
    },
    {
      name: 'Rejected',
      value: 'REJECTED'
    }
  ];

  static allticketsTypes: any = [
    {
      name: 'Admission Ticket',
      value: 'ADMISSION_TICKET'
    },
    {
      name: 'Re-Registration Ticket',
      value: 'RE_REGISTRATION_TICKET'
    },
    {
      name: 'Examination Ticket',
      value: 'EXAMINATION_TICKET'
    },
    {
      name: 'Marksheet Ticket',
      value: 'MARKSHEET_TICKET'
    },
    {
      name: 'Transcript Ticket',
      value: 'TRANSCRIPT_TICKET'
    },
    {
      name: 'Revaluation Ticket',
      value: 'REVALUATION_TICKET'
    },
    {
      name: 'Migration Ticket',
      value: 'MIGRATION_TICKET'
    },
    {
      name: 'Original Degree Ticket',
      value: 'ORIGINAL_DEGREE_TICKET'
    },
    {
      name: 'E-Learning Ticket',
      value: 'E_LEARNING_TICKET'
    },
    {
      name: 'Other Ticket',
      value: 'OTHER_TICKET'
    }
  ];

  static centerTicketTypes: any = [
    {
      name: 'Admission Ticket',
      value: 'ADMISSION_TICKET'
    },
    {
      name: 'Lead Ticket',
      value: 'LEAD_TICKET'
    },
    {
      name: 'Other Ticket',
      value: 'OTHER_TICKET'
    }
  ];

  static centerNotificationTypes: any = [
    {
      name: 'Admission',
      value: 'ADMISSION'
    },
    {
      name: 'Other',
      value: 'OTHER'
    }
  ];

  static issueStatuses: any = [
    {
      name: 'Pending',
      value: 'PENDING'
    },
    {
      name: 'Payment Received',
      value: 'PAYMENT_RECEIVED'
    },
    {
      name: 'Out For Delivery',
      value: 'OUT_FOR_DELIVERY'
    },
    {
      name: 'Delivered',
      value: 'DELIVERED'
    },
    {
      name: 'Payment Not Required',
      value: 'PAYMENT_NOT_REQUIRED'
    }
  ];

  static issuePaymentStatuses: any = [
    {
      name: 'Pending',
      value: 'PENDING'
    },
    {
      name: 'Success',
      value: 'SUCCESS'
    },
    {
      name: 'Failed',
      value: 'FAILED'
    }
  ];

  static receiptStatus: any = [
    {
      name: 'Pending',
      value: 'PENDING'
    },
    {
      name: 'Accepted',
      value: 'ACCEPTED'
    },
    {
      name: 'Rejected',
      value: 'REJECTED'
    }
  ];

  static admissionTypes: any = [
    {
      name: 'Fresh Admission',
      value: 'FRESH_ADMISSION',
      default_semester: 1
    },
    {
      name: 'Lateral Entry',
      value: 'LATERAL_ENTRY',
      default_semester: 3
    },
    {
      name: 'Credit Transfer',
      value: 'CREDIT_TRANSFER',
      default_semester: 5
    }
  ];

  static year: any = [
    {
      name: '2024',
      value: '2024'
    },
    {
      name: '2025',
      value: '2025'
    }
  ];

  static months: any = [
    {
      name: 'January',
      value: 1
    },
    {
      name: 'February',
      value: 2
    },
    {
      name: 'March',
      value: 3
    },
    {
      name: 'April',
      value: 4
    },
    {
      name: 'May',
      value: 5
    },
    {
      name: 'June',
      value: 6
    },
    {
      name: 'July',
      value: 7
    },
    {
      name: 'August',
      value: 8
    },
    {
      name: 'September',
      value: 9
    },
    {
      name: 'October',
      value: 10
    },
    {
      name: 'November',
      value: 11
    },
    {
      name: 'December',
      value: 12
    }
  ];

  static unRemovedYears: any = [1, 2, 3];

  static removedYears: any = [2, 3];

  static sessionTypes: any = [
    {
      name: 'Calendar',
      value: 'CAL'
    },
    {
      name: 'Academic',
      value: 'ACD'
    }
  ];

  static subjectTypes: any = [
    {
      name: 'Theory',
      value: 'THEORY'
    },
    {
      name: 'Practical',
      value: 'PRACTICAL'
    }
  ];

  static transferredStudentStatuses: any = [
    {
      name: 'Pending',
      value: 'PENDING'
    },
    {
      name: 'Transferred',
      value: 'TRANSFERRED'
    },
    {
      name: 'Rejected',
      value: 'REJECTED'
    }
  ];

  static filterBackMarkStatusForUniversity: any = [
    {
      name: 'Marked',
      value: 'MARKED_BY_CENTER'
    },
    {
      name: 'Rejected',
      value: 'REJECTED_BY_UNIVERSITY'
    }
  ];

  static questionTypes: any = {
    objective: {
      question_type: ['objective']
    },
    subjective: {
      question_type: ['subjective']
    }
  };

  static noOfPerioods = [5];
  static lunchAfterPeriod = [2];

  static semester_nos = [1, 2, 3, 4, 5, 6, 7, 8];
  static govIdTypes: any = [
    {
      name: 'Aadhar Card',
      value: 'AADHAAR'
    },
    {
      name: 'Driving License',
      value: 'DRIVING_LICENCE'
    },
    {
      name: 'Voter ID',
      value: 'VOTER_ID'
    },
    {
      name: 'Passport',
      value: 'PASSPORT'
    },
    {
      name: 'Other',
      value: 'OTHER'
    }
  ];
  static maritalStatues: any = [
    {
      name: 'Single',
      value: 'SINGLE'
    },
    {
      name: 'married',
      value: 'MARRIED'
    },
    {
      name: 'Divorced',
      value: 'DIVORCED'
    },
    {
      name: 'Widowed',
      value: 'WIDOWED'
    },
    {
      name: 'Separated',
      value: 'SEPARATED'
    }
  ];
  static genders: any = [
    {
      name: 'Male',
      value: 'MALE'
    },
    {
      name: 'Female',
      value: 'FEMALE'
    },
    {
      name: 'Others',
      value: 'OTHER'
    }
  ];

  static category: any = [
    {
      name: 'Fresh Admission',
      value: 'FRESH_ADMISSION'
    },
    {
      name: 'Lateral Entry',
      value: 'LATERAL_ENTRY'
    },
    {
      name: 'Credit Transfer',
      value: 'CREDIT_TRANSFER'
    }
  ];

  static divisions: any = [
    {
      name: 'First',
      value: 1
    },
    {
      name: 'Second',
      value: 2
    },
    {
      name: 'Third',
      value: 3
    }
  ];

  static religions: any = [
    {
      name: 'Hindu',
      value: 'HINDU'
    },
    {
      name: 'Muslim',
      value: 'MUSLIM'
    },
    {
      name: 'Christian',
      value: 'CHRISTIAN'
    },
    {
      name: 'Jain',
      value: 'JAIN'
    },
    {
      name: 'Buddhist',
      value: 'BUDDHIST'
    },
    {
      name: 'Sikh',
      value: 'SIKH'
    },
    {
      name: 'Others',
      value: 'OTHERS'
    }
  ];

  static nationalities: any = [
    {
      name: 'Indian',
      value: 'INDIAN'
    },
    {
      name: 'Others',
      value: 'OTHERS'
    }
  ];

  static transactionTypes: any = [
    {
      name: 'NEFT',
      value: 'NEFT'
    },
    {
      name: 'DD',
      value: 'DD'
    },
    {
      name: 'IMPS',
      value: 'IMPS'
    },
    {
      name: 'UPI',
      value: 'UPI'
    },
    {
      name: 'Cheque Deposit',
      value: 'CHEQUE'
    },
    {
      name: 'Bank Cash Deposit',
      value: 'BANK_CASH_DEPOSIT'
    },
    {
      name: 'Cash',
      value: 'CASH'
    },
    {
      name: 'Other',
      value: 'OTHER'
    }
  ];

  static banks: any = [
    {
      name: 'Axis Bank',
      value: 'AXIS'
    },
    {
      name: 'ICICI Bank',
      value: 'ICICI'
    },
    {
      name: 'State Bank of India (SBI)',
      value: 'SBI'
    },
    {
      name: 'Central Bank of India (CBI)',
      value: 'CBI'
    },
    {
      name: 'HDFC Bank',
      value: 'HDFC'
    },
    {
      name: 'IDFC Bank',
      value: 'IDFC'
    },
    {
      name: 'Punjab National Bank (PNB)',
      value: 'PNB'
    },
    {
      name: 'Canara Bank',
      value: 'CANARA'
    },
    {
      name: 'Other Bank',
      value: 'OTHER'
    }
  ];

  static receiptStatuses: any = [
    {
      name: 'Pending',
      value: 'PENDING'
    },
    {
      name: 'Accepted',
      value: 'ACCEPTED'
    },
    {
      name: 'Rejected',
      value: 'REJECTED'
    }
  ];

  static years: any = [
    {
      name: '2025',
      value: '2025'
    },
    {
      name: '2024',
      value: '2024'
    },
    {
      name: '2023',
      value: '2023'
    },
    {
      name: '2022',
      value: '2022'
    },
    {
      name: '2021',
      value: '2021'
    }
  ];

  static getDaysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  static days = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday'
  ];

  static attendanceTypes: any = [
    {
      name: 'Present',
      value: 'PRESENT'
    },
    {
      name: 'Absent',
      value: 'ABSENT'
    },
    {
      name: 'Half Day',
      value: 'HALF_DAY'
    },
    {
      name: 'Leave',
      value: 'LEAVE'
    }
  ];

  static studentAttendanceTypes: any = [
    {
      name: 'Present',
      value: 'PRESENT'
    },
    {
      name: 'Absent',
      value: 'ABSENT'
    },
    {
      name: 'Leave',
      value: 'LEAVE'
    }
  ];

  static borrowedBookLimit = 5;

  static ratings: any = [
    {
      name: 'Exceeds Expectations',
      value: 'Exceeds Expectations'
    },
    {
      name: 'Meets Expectations',
      value: 'Meets Expectations'
    },
    {
      name: 'Needs Improvements',
      value: 'Needs Improvements'
    },
    {
      name: 'Unacceptable',
      value: 'Unacceptable'
    }
  ];

  static bloodGroups: any = [
    {
      name: 'A+',
      value: 'A+'
    },
    {
      name: 'A-',
      value: 'A-'
    },
    {
      name: 'B+',
      value: 'B-'
    },
    {
      name: 'O+',
      value: 'O-'
    },
    {
      name: 'AB+',
      value: 'AB-'
    }
  ];

  static attendance_years: any = [
    {
      name: '2024',
      value: '2024'
    },
    {
      name: '2023',
      value: '2023'
    },
    {
      name: '2022',
      value: '2022'
    },
    {
      name: '2021',
      value: '2021'
    }
  ];

  static office_location_latitude = 28.6064602;
  static office_location_longitude = 77.0809699;
  static vehicleTypes: any = [
    {
      name: 'Luxury Bus',
      value: 'LUXURY_BUS'
    },
    {
      name: 'Standard Bus',
      value: 'STANDARD_BUS'
    },
    {
      name: 'Mini Bus',
      value: 'MINI_BUS'
    },
    {
      name: 'Small Vans',
      value: 'SMALL_VANS'
    },
    {
      name: 'Large Vans',
      value: 'LARGE_VANS'
    },
    {
      name: 'Mini Cab',
      value: 'MINI_CAB'
    },
    {
      name: 'SUV Cab',
      value: 'SUV_CAB'
    }
  ];

  static occupancyTypes: any = [1, 2, 3, 4, 5, 6];

  static feeKeys: any = [
    'rcf', 'tuition_fee', 'erp_pdp_fee', 'registration_fee', 'prospectus_fee', 'examination_fee', 'alumni_council_charges', 'digital_skill_course_charges',
    'placement_cell_charges', 'science_and_tech_charges', 'sports_facilities_charges', 'annual_day_charges', 'university_tshirt_charges'
  ];
}
