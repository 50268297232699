<div class="h-screen overflow-y-hidden">
<app-header></app-header>
<div class="container-fluid bg-enter-details h-full">
  <div class="grid grid-cols-1 items-center md:grid-cols-2 h-full">
    <div class="p-6 mb-6 flex flex-col items-center">
      <div class="white-color text-center py-5">
        <p class="text-lg">Your code has been sent to the mobile number ending with XXXXXX<strong>{{authData?.contact?.slice(-4)}}</strong>.</p>
        <h3>We've sent a 6-character code. The code expires shortly, so please enter it soon.</h3>
      </div>
      <div class="flex justify-center gap-4 mt-6 py-6">
        <input #inputField type="text" class="otp-input" numbersOnly maxlength="1" (input)="verify()">
        <input #inputField type="text" class="otp-input" numbersOnly maxlength="1" (input)="verify()">
        <input #inputField type="text" class="otp-input" numbersOnly maxlength="1" (input)="verify()">
        <input #inputField type="text" class="otp-input" numbersOnly maxlength="1" (input)="verify()">
        <input #inputField type="text" class="otp-input" numbersOnly maxlength="1" (input)="verify()">
        <input #inputField type="text" class="otp-input" numbersOnly maxlength="1" (input)="verify()">
      </div>
      <div class="mt-6 text-center">
        <p class="color-white text-center p-2">Didn't you receive the OTP?<span *ngIf="showResendBtn" (click)="resend()" class="text-danger ml-1 underline cursor-pointer">Resend OTP</span><span *ngIf="!showResendBtn" class="ml-1"><span class="text-danger">Resend OTP</span> in {{this.formattedTime}}</span>
        </p>
      </div>
    </div>
    <div class="p-6 mb-6">
      <img src="assets/images/OTP%201.png" alt="Image" class="w-[60%] m-auto h-auto"/>
    </div>
  </div>
</div>
</div>
