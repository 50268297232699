import {Component, ElementRef, QueryList, ViewChildren} from '@angular/core';
import {AuthService} from "../../shared/services/auth.service";
import {AlertService} from "../../shared/services/alert.service";
import {StorageService} from "../../shared/services/storage.service";
import {GlobalService} from "../../shared/services/global.service";
import {Router} from "@angular/router";
import {NavigationLoaderService} from "../../core/navigation/navigation-loader.service";
import {HeaderComponent} from "../../landing/header/header.component";
import {NgIf} from "@angular/common";
import {SharedModule} from "../../shared/shared.module";

@Component({
  selector: 'vex-verify-otp',
  standalone: true,
  imports: [
    HeaderComponent,
    NgIf,
    SharedModule
  ],
  templateUrl: './verify-otp.component.html',
  styleUrl: './verify-otp.component.scss'
})
export class VerifyOtpComponent {
  @ViewChildren('inputField') inputFields!: QueryList<ElementRef>;
  timeLeft: number = 60
  timerInterval: any
  formattedTime: string = '01:00';
  showResendBtn: boolean = false;
  authData: any
  contact: any;

  constructor(
    private authService: AuthService,
    private globalService: GlobalService,
    private navigationService: NavigationLoaderService,
    private router: Router
  ) {
    this.authData = StorageService.getItem('auth');
    this.startTimer();
  }

  startTimer(): void {
    this.showResendBtn = false;
    this.timeLeft = 60
    this.timerInterval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
        this.formattedTime = this.formatTime(this.timeLeft);
      } else {
        this.showResendBtn = true;
        this.clearTimer();
      }
    }, 1000);
  }

  clearTimer(): void {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
  }

  formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  }

  ngAfterViewInit() {
    const inputs: any = document.querySelectorAll('.otp-input');
    inputs[0].focus()
    inputs.forEach((input: any, index) => {
      input.addEventListener('input', (event: any) => {
        if (event.inputType === 'insertText' && input.value) {
          const nextInput: any = inputs[index + 1];
          if (nextInput) {
            nextInput.focus();
          }
        }
      });

      input.addEventListener('keydown', (event) => {
        if (event.key === 'Backspace' && !input.value) {
          const previousInput: any = inputs[index - 1];
          if (previousInput) {
            previousInput.focus();
          }
        }
      });
    });
  }

  resend() {
    this.startTimer();
    this.authService.resendOtp({
      unique_id: this.authData.unique_id,
    }).subscribe(data => {
      this.authData.decoded_otp = data.decoded_otp;
      StorageService.setItem('auth', this.authData);
    }, error => {});
  }

  verify() {
    let otpInput: string = ''
    this.inputFields.forEach(field => {
      otpInput += field.nativeElement.value;
    })
    if (otpInput.length < 6) return
    this.authData = StorageService.getItem('auth')
    let params: any = {
      unique_id: this.authData.unique_id,
      otp: otpInput,
      decoded_otp: this.authData.decoded_otp
    }
    this.authService.verifyOtp(params).subscribe(data => {
      StorageService.removeItem('auth');
      if (data && data.id) {
        this.globalService.setAccessToken(data?.token);
        this.globalService.setSelf({
          'name': data?.name,
          'email': data?.email,
          'contact': data?.contact,
          'user_id': data?.id,
          'username': data?.username,
          'user_type': data?.user_type,
          'image': data?.image
        });
        this.navigationService.getSelfDetails();
        const checkout = localStorage.getItem('checkout');
        if (checkout) {
          this.router.navigateByUrl(checkout);
          localStorage.removeItem('checkout');
        } else {
          this.router.navigateByUrl('/home');
        }
      }

    }, error => {
      this.inputFields.forEach(field => {
        field.nativeElement.value = '';
      })
      let firstInput: any = document.querySelector('.otp-input')
      firstInput.focus()
    })
  }

}
