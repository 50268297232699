import {Injectable} from '@angular/core';

@Injectable()
export class PermissionConstant {
  static ALL = 'ALL';

  // Dashboard
  static DASHBOARD: string = 'DASHBOARDS';
  static HOME: string = 'DASHBOARDS_HOME';

  // General
  static GENERAL: string = 'GENERALS';
  static ADMIN: string = 'GENERALS_ADMINS';
  static ADMIN_ADD: string = 'GENERALS_ADMINS_ADD';
  static ADMIN_LIST: string = 'GENERALS_ADMINS_LIST';
  static ADMIN_UPDATE: string = 'GENERALS_ADMINS_LIST_UPDATE';
  static ADMIN_PASSWORD: string = 'GENERALS_ADMINS_LIST_PASSWORD';
  static ADMIN_PROFILE_IMAGE: string = 'GENERALS_ADMINS_LIST_PROFILE_IMAGE';
  static ADMIN_STATUS: string = 'GENERALS_ADMINS_LIST_STATUS';
  static ADMIN_DELETE: string = 'GENERALS_ADMINS_LIST_DELETE';
  static CENTER: string = 'GENERALS_CENTERS';
  static CENTER_ADD: string = 'GENERALS_CENTERS_ADD';
  static CENTER_LIST: string = 'GENERALS_CENTERS_LIST';
  static CENTER_SHARING_PERCENTAGE: string = 'GENERALS_CENTERS_LIST_SHARING_PERCENTAGE';
  static CENTER_UPDATE: string = 'GENERALS_CENTERS_LIST_UPDATE';
  static CENTER_PASSWORD: string = 'GENERALS_CENTERS_LIST_PASSWORD';
  static CENTER_PROFILE_IMAGE: string = 'GENERALS_CENTERS_LIST_PROFILE_IMAGE';
  static CENTER_STATUS: string = 'GENERALS_CENTERS_LIST_STATUS';
  static CENTER_DELETE:  string = 'GENERALS_CENTERS_LIST_DELETE';
  static CENTER_WALLET_RECHARGE: string = 'GENERALS_CENTERS_LIST_WALLET_RECHARGE';
  static SESSION: string = 'GENERALS_SESSIONS';
  static SESSION_ADD: string = 'GENERALS_SESSIONS_ADD';
  static SESSION_LIST: string = 'GENERALS_SESSIONS_LIST';
  static SESSION_ADMISSION_STATUS: string = 'GENERALS_SESSIONS_LIST_ADMISSION_STATUS';
  static SESSION_DEFAULT: string = 'GENERALS_SESSIONS_LIST_DEFAULT';
  static SESSION_UPDATE: string = 'GENERALS_SESSIONS_LIST_UPDATE';
  static SESSION_STATUS: string = 'GENERALS_SESSIONS_LIST_STATUS';
  static SESSION_DELETE: string = 'GENERALS_SESSIONS_LIST_DELETE';
  static MEETINGS: string = 'GENERALS_MEETINGS';
  static MEETING_PERSON: string = 'GENERALS_MEETINGS_PERSONS';
  static MEETING_PERSON_ADD: string = 'GENERALS_MEETINGS_PERSONS_ADD';
  static MEETING_PERSON_UPDATE: string = 'GENERALS_MEETINGS_PERSONS_UPDATE';
  static MEETING_PERSON_STATUS: string = 'GENERALS_MEETINGS_PERSONS_STATUS';
  static PERSON_DELETE: string = 'GENERALS_MEETINGS_PERSONS_DELETE';
  static MEETING_SCHEDULE: string = 'GENERALS_MEETINGS_SCHEDULE';
  static MEETING_SCHEDULE_ADD: string = 'GENERALS_MEETINGS_SCHEDULE_ADD';
  static MEETINGS_SCHEDULE_DETAIL: string = 'GENERALS_MEETINGS_SCHEDULE_DETAIL';
  static MEETING_SCHEDULE_VISITOR_TOKEN: string = 'GENERALS_MEETINGS_SCHEDULE_VISITOR_TOKEN';
  static MEETING_SCHEDULE_UPDATE: string = 'GENERALS_MEETINGS_SCHEDULE_UPDATE';
  static MEETING_SCHEDULE_DELETE: string = 'GENERALS_MEETINGS_SCHEDULE_DELETE';
  static GENERAL_REPORT: string = 'GENERALS_REPORTS';

  // Employee
  static EMPLOYEE: string = 'EMPLOYEES';
  static DEPARTMENT: string = 'EMPLOYEES_DEPARTMENTS';
  static DEPARTMENT_ADD: string = 'EMPLOYEES_DEPARTMENTS_ADD';
  static DEPARTMENT_UPDATE: string = 'EMPLOYEES_DEPARTMENTS_UPDATE';
  static DEPARTMENT_STATUS: string = 'EMPLOYEES_DEPARTMENTS_STATUS';
  static DEPARTMENT_DELETE: string = 'EMPLOYEES_DEPARTMENTS_DELETE';
  static DESIGNATION: string = 'EMPLOYEES_DESIGNATIONS';
  static DESIGNATION_ADD: string = 'EMPLOYEES_DESIGNATIONS_ADD';
  static DESIGNATION_UPDATE: string = 'EMPLOYEES_DESIGNATIONS_UPDATE';
  static DESIGNATION_STATUS: string = 'EMPLOYEES_DESIGNATIONS_STATUS';
  static DESIGNATION_DELETE: string = 'EMPLOYEES_DESIGNATIONS_DELETE';
  static DESIGNATION_PERMISSION: string = 'EMPLOYEES_DESIGNATIONS_PERMISSION';
  static EMPLOYEE_ADD: string = 'EMPLOYEES_ADD';
  static EMPLOYEE_LIST: string = 'EMPLOYEES_EMPLOYEES_LIST';
  static EMPLOYEE_EDIT: string = 'EMPLOYEES_EMPLOYEES_LIST_EDIT';
  static EMPLOYEE_UPDATE: string = 'EMPLOYEES_EMPLOYEES_LIST_UPDATE';
  static EMPLOYEE_PASSWORD: string = 'EMPLOYEES_EMPLOYEES_LIST_PASSWORD';
  static EMPLOYEE_HOD: string = 'EMPLOYEES_EMPLOYEES_LIST_HOD';
  static EMPLOYEE_SALARY: string = 'EMPLOYEES_EMPLOYEES_LIST_SALARY';
  static EMPLOYEE_EVALUATION: string = 'EMPLOYEES_EMPLOYEES_LIST_EVALUATION';
  static EMPLOYEE_STATUS: string = 'EMPLOYEES_EMPLOYEES_LIST_STATUS';
  static EMPLOYEE_DELETE: string = 'EMPLOYEES_EMPLOYEES_LIST_DELETE';
  static EMPLOYEE_ATTENDANCE: string = 'EMPLOYEES_EMPLOYEES_ATTENDANCE';
  static EMPLOYEE_ATTENDANCE_MARK: string = 'EMPLOYEES_EMPLOYEES_ATTENDANCE_MARK';
  static EMPLOYEE_PAYROLL: string = 'EMPLOYEES_EMPLOYEES_PAYROLL';
  static PERMISSION: string = 'EMPLOYEES_PERMISSIONS';
  static PERMISSION_ADD: string = 'EMPLOYEES_PERMISSIONS_ADD';
  static PERMISSION_UPDATE: string = 'EMPLOYEES_PERMISSIONS_UPDATE';
  static PERMISSION_STATUS: string = 'EMPLOYEES_PERMISSIONS_STATUS';
  static PERMISSION_SUB_PERMISSION: string = 'EMPLOYEES_PERMISSIONS_SUB_PERMISSION';
  static PERMISSION_DELETE: string = 'EMPLOYEES_PERMISSIONS_DELETE';
  static RESIGNATION: string = 'EMPLOYEES_RESIGNATION';
  static RESIGNATION_DETAIL: string = 'EMPLOYEES_RESIGNATION_DETAIL';
  static RESIGNATION_DOCUMENT: string = 'EMPLOYEES_RESIGNATION_DOCUMENT';
  static EMPLOYEE_REPORT: string = 'EMPLOYEES_REPORTS';
  static EMPLOYEE_RESIGNATION = 'EMPLOYEES_RESIGNATION_EMPLOYEES';

  // Module
  static MODULE: string = 'MODULES';
  static SCHOOL: string = 'MODULES_SCHOOLS';
  static PROGRAM: string = 'MODULES_PROGRAMS';
  static PROGRAM_ADD: string = 'MODULES_PROGRAMS_ADD';
  static PROGRAM_UPDATE: string = 'MODULES_PROGRAMS_UPDATE';
  static PROGRAM_STATUS: string = 'MODULES_PROGRAMS_STATUS';
  static PROGRAM_DELETE: string = 'MODULES_PROGRAMS_DELETE';
  static COURSE: string = 'MODULES_COURSES';
  static COURSE_ADD: string = 'MODULES_COURSES_ADD';
  static COURSE_UPDATE: string = 'MODULES_COURSES_UPDATE';
  static COURSE_STATUS: string = 'MODULES_COURSES_STATUS';
  static COURSE_DELETE: string = 'MODULES_COURSES_DELETE';
  static BRANCH: string = 'MODULES_BRANCHES';
  static BRANCH_ADD: string = 'MODULES_BRANCHES_ADD';
  static BRANCH_UPDATE: string = 'MODULES_BRANCHES_UPDATE';
  static BRANCH_STATUS: string = 'MODULES_BRANCHES_STATUS';
  static BRANCH_DELETE: string = 'MODULES_BRANCHES_DELETE';
  static MAPPING: string = 'MODULES_MAPPINGS';
  static MAPPING_ADD: string = 'MODULES_MAPPINGS_ADD';
  static MAPPING_FEE_STRUCTURE: string = 'MODULES_MAPPINGS_FEE_STRUCTURE';
  static MAPPING_HOD: string = 'MODULES_MAPPINGS_HOD';
  static MAPPING_SUBJECT: string = 'MODULES_MAPPINGS_SUBJECT';
  static MAPPING_SECTION: string = 'MODULES_MAPPINGS_SECTION';
  static MAPPING_UPDATE: string = 'MODULES_MAPPINGS_UPDATE';
  static MAPPING_STATUS: string = 'MODULES_MAPPINGS_STATUS';
  static MAPPING_DELETE: string = 'MODULES_MAPPINGS_DELETE';
  static SUBJECT: string = 'MODULES_SUBJECTS';
  static SUBJECT_ADD: string = 'MODULES_SUBJECTS_ADD';
  static SUBJECT_SLM: string = 'MODULES_SUBJECTS_SLM';
  static SUBJECT_NOTE: string = 'MODULES_SUBJECTS_NOTE';
  static SUBJECT_UPDATE: string = 'MODULES_SUBJECTS_UPDATE';
  static SUBJECT_STATUS: string = 'MODULES_SUBJECTS_STATUS';
  static SUBJECT_CHAPTER: string = 'MODULES_SUBJECTS_CHAPTER';
  static SUBJECT_DELETE: string = 'MODULES_SUBJECTS_DELETE';
  static MODULE_REPORT: string = 'MODULES_REPORTS';

  // Inventory
  static INVENTORY: string = 'INVENTORIES';
  static INVENTORY_CATEGORY: string = 'INVENTORIES_CATEGORIES';
  static INVENTORY_CATEGORY_ADD: string = 'INVENTORIES_CATEGORIES_ADD';
  static INVENTORY_CATEGORY_UPDATE: string = 'INVENTORIES_CATEGORIES_UPDATE';
  static INVENTORY_CATEGORY_STATUS: string = 'INVENTORIES_CATEGORIES_STATUS';
  static INVENTORY_CATEGORY_DELETE: string = 'INVENTORIES_CATEGORIES_DELETE';
  static INVENTORY_LIST: string = 'INVENTORIES_LIST';
  static INVENTORY_LIST_ADD: string = 'INVENTORIES_LIST_ADD';
  static INVENTORY_LIST_UPDATE: string = 'INVENTORIES_LIST_UPDATE';
  static INVENTORY_LIST_STOCK: string = 'INVENTORIES_LIST_STOCK';
  static INVENTORY_LIST_STATUS: string = 'INVENTORIES_LIST_STATUS';
  static INVENTORY_LIST_DELETE: string = 'INVENTORIES_LIST_DELETE';
  static INVENTORY_TRANSACTION: string = 'INVENTORIES_TRANSACTIONS';
  static INVENTORY_TRANSACTION_STATUS: string = 'INVENTORIES_TRANSACTIONS_STATUS';
  static INVENTORY_REPORT: string = 'INVENTORIES_REPORTS';

  // Book
  static BOOK: string = 'BOOKS';
  static BOOK_CATEGORY: string = 'BOOKS_CATEGORIES';
  static BOOK_CATEGORY_ADD: string = 'BOOKS_CATEGORIES_ADD';
  static BOOK_CATEGORY_UPDATE: string = 'BOOKS_CATEGORIES_UPDATE';
  static BOOK_CATEGORY_STATUS: string = 'BOOKS_CATEGORIES_STATUS';
  static BOOK_CATEGORY_DELETE: string = 'BOOKS_CATEGORIES_DELETE';
  static BOOK_LIST: string = 'BOOKS_LIST';
  static BOOK_ADD: string = 'BOOKS_LIST_ADD';
  static BOOK_ISBN: string = 'BOOKS_LIST_ISBN';
  static BOOK_COVER: string = 'BOOKS_LIST_BOOK_COVER';
  static BOOK_UPDATE: string = 'BOOKS_LIST_UPDATE';
  static BOOK_STOCK: string = 'BOOKS_LIST_STOCK';
  static BOOK_STATUS: string = 'BOOKS_LIST_STATUS';
  static BOOK_DELETE: string = 'BOOKS_LIST_DELETE';
  static BOOK_OPEN: string = 'BOOKS_LIST_OPEN';
  static BOOK_TRANSACTION: string = 'BOOKS_TRANSACTIONS';
  static BOOK_TRANSACTION_STATUS: string = 'BOOKS_TRANSACTIONS_STATUS';
  static BOOK_REPORT: string = 'BOOKS_REPORTS';

  // Library
  static LIBRARY: string = 'LIBRARIES';
  static LIBRARY_BORROWING: string = 'LIBRARIES_BORROWINGS';
  static LIBRARY_BORROWING_ADD: string = 'LIBRARIES_BORROWINGS_ADD';
  static LIBRARY_BORROWING_RETURN: string = 'LIBRARIES_BORROWINGS_RETURN';
  static LIBRARY_BORROWING_STATUS: string = 'LIBRARIES_BORROWINGS_STATUS';
  static LIBRARY_BORROWING_DELETE: string = 'LIBRARIES_BORROWINGS_DELETE';
  static LIBRARY_TRANSACTION: string = 'LIBRARIES_TRANSACTIONS';
  static LIBRARY_TRANSACTION_STATUS: string = 'LIBRARIES_TRANSACTIONS_STATUS';
  static LIBRARY_REPORT: string = 'LIBRARIES_REPORTS';

  // Student
  static STUDENT: string = 'STUDENTS';
  static STUDENT_ADD: string = 'STUDENTS_ADD';
  static STUDENT_LIST: string = 'STUDENTS_DIRECT_STUDENTS';
  static STUDENT_PROFILE: string = 'STUDENTS_DIRECT_PROFILE';
  static STUDENT_EXCEL: string = 'STUDENTS_DIRECT_EXCEL';
  static STUDENT_DIRECT_EDIT: string = 'STUDENTS_DIRECT_EDIT';
  static STUDENT_DIRECT_FEE: string = 'STUDENTS_DIRECT_FEE';
  static STUDENT_DIRECT_DELETE: string = 'STUDENTS_DIRECT_DELETE';
  static STUDENT_VERIFY: string = 'STUDENTS_VERIFY_DIRECT_STUDENT';
  static STUDENT_DIRECT_VERIFY: string = 'STUDENTS_VERIFY_DIRECT_STUDENT_VERIFY';
  static STUDENT_CENTER: string = 'STUDENTS_CENTER_STUDENT';
  static STUDENT_CENTER_PROFILE: string = 'STUDENTS_CENTER_STUDENT_PROFILE';
  static STUDENT_CENTER_EXCEL: string = 'STUDENTS_CENTER_STUDENT_EXCEL';
  static STUDENT_CENTER_FEE: string = 'STUDENTS_CENTER_STUDENT_FEE';
  static STUDENT_VERIFY_CENTER: string = 'STUDENTS_VERIFY_CENTER_STUDENT';
  static STUDENT_VERIFY_CENTER_VERIFY: string = 'STUDENTS_VERIFY_CENTER_STUDENT_VERIFY';
  static STUDENT_SCHOLARSHIP: string = 'STUDENTS_VERIFY_SCHOLARSHIP';
  static STUDENT_TRANSACTION: string = 'STUDENTS_DIRECT_TRANSACTION';
  static STUDENT_TRANSACTION_RECEIPT: string = 'STUDENTS_DIRECT_TRANSACTION_RECEIPT';
  static STUDENT_ATTENDANCE: string = 'STUDENTS_ATTENDANCE';
  static STUDENT_ATTENDANCE_MARK: string = 'STUDENTS_ATTENDANCE_MARK';
  static STUDENT_ENROLLMENT: string = 'STUDENTS_ENROLLMENT';
  static STUDENT_ENROLLMENT_ASSIGN: string = 'STUDENTS_ENROLLMENT_ASSIGN';
  static FINAL_STUDENT_LIST: string = 'STUDENTS_FINAL_STUDENT';
  static FINAL_STUDENT_PROFILE: string = 'STUDENTS_FINAL_STUDENT_PROFILE';
  static FINAL_STUDENT_EXCEL: string = 'STUDENTS_FINAL_STUDENT_EXCEL';
  static FINAL_STUDENT_EDIT: string = 'STUDENTS_FINAL_STUDENT_EDIT';
  static FINAL_STUDENT_FEE: string = 'STUDENTS_FINAL_STUDENT_FEE';
  static STUDENT_FINAL_VERIFICATION: string = 'STUDENTS_FINAL_VERIFICATION';
  static STUDENT_FINAL_VERIFICATION_VERIFY: string = 'STUDENTS_FINAL_VERIFICATION_VERIFY';
  static STUDENT_FINAL_VERIFICATION_DETAIL: string = 'STUDENTS_FINAL_VERIFICATION_DETAIL';
  static STUDENT_PROGRESS_MANAGER: string = 'STUDENTS_PROGRESS_MANAGER';
  static STUDENT_PROGRESS_MANAGER_ASSIGNMENT: string = 'STUDENTS_PROGRESS_MANAGER_ASSIGNMENT';
  static STUDENT_HOSTEL: string = 'STUDENTS_HOSTEL_STUDENT';
  static STUDENT_VEHICLE: string = 'STUDENTS_VEHICLE_STUDENT';
  static FINALISED_STUDENT_LIST = 'STUDENTS_FINALISED_STUDENT_LIST';
  static HOSTEL_STUDENT_LIST = 'STUDENTS_HOSTEL_STUDENT';
  static STUDENT_REPORT: string = 'STUDENTS_REPORTS';

  // BDE
  static BDE: string = 'BDES';
  static BDE_ADD: string = 'BDES_ADD';
  static BDE_LIST: string = 'BDES_LIST';
  static BDE_EXCEL: string = 'BDES_LIST_EXCEL';
  static BDE_EDIT: string = 'BDES_LIST_EDIT';
  static BDE_FEE: string = 'BDES_LIST_FEE';
  static BDE_RESULT: string = 'BDES_LIST_RESULT';
  static BDE_DOCUMENTS: string = 'BDES_LIST_DOCUMENT';
  static BDE_DELETE: string = 'BDES_LIST_DELETE';
  static BDE_PAY_FEE = 'BDE_PAY_FEE';

  // Account
  static ACCOUNT_ACCOUNT: string = 'ACCOUNTS';
  static ACCOUNT_RECEIPT: string = 'ACCOUNTS_RECEIPT';
  static ACCOUNT_RECEIPT_STATUS: string = 'ACCOUNTS_RECEIPT_STATUS';
  static ACCOUNT_RECEIPT_DOCUMENT: string = 'ACCOUNTS_RECEIPT_DOCUMENT';
  static ACCOUNT_RECEIPT_DETAIL: string = 'ACCOUNTS_RECEIPT_DETAIL';
  static ACCOUNT_RECEIPT_TRANSACTION: string = 'ACCOUNTS_RECEIPT_TRANSACTION';
  static ACCOUNT_WALLET_TRANSACTION: string = 'ACCOUNTS_WALLET_TRANSACTION';
  static ACCOUNT_REPORT: string = 'ACCOUNTS_REPORTS';

  // Exams
  static EXAMS: string = 'EXAMS';
  static INTERNAL: string = 'EXAMS_INTERNAL';
  static INTERNAL_EXAM_SESSION: string = 'EXAMS_INTERNAL_SESSIONS';
  static INTERNAL_EXAM_SESSION_ADD: string = 'EXAMS_INTERNAL_SESSIONS_ADD';
  static INTERNAL_EXAM_SESSION_UPDATE: string = 'EXAMS_INTERNAL_SESSIONS_UPDATE';
  static INTERNAL_EXAM_SESSION_STATUS: string = 'EXAMS_INTERNAL_SESSIONS_STATUS';
  static INTERNAL_EXAM_SESSION_DELETE: string = 'EXAMS_INTERNAL_SESSIONS_DELETE';
  static INTERNAL_EXAM_QUESTIONS: string = 'EXAMS_INTERNAL_EXAM_QUESTIONS';
  static INTERNAL_EXAM_QUESTIONS_UPLOAD: string = 'EXAMS_INTERNAL_EXAM_QUESTIONS_UPLOAD';
  static INTERNAL_EXAM_SCHEDULER: string = 'EXAMS_INTERNAL_SCHEDULER';
  static INTERNAL_EXAM_SCHEDULER_ADD: string = 'EXAMS_INTERNAL_SCHEDULER_ADD';
  static INTERNAL_EXAM_SCHEDULER_FETCH: string = 'EXAMS_INTERNAL_SCHEDULER_FETCH';
  static INTERNAL_EXAM_SCHEDULER_UPDATE: string = 'EXAMS_INTERNAL_SCHEDULER_UPDATE';
  static INTERNAL_EXAM_SCHEDULER_STATUS: string = 'EXAMS_INTERNAL_SCHEDULER_STATUS';
  static INTERNAL_EXAM_SCHEDULER_DELETE: string = 'EXAMS_INTERNAL_SCHEDULER_DELETE';
  static INTERNAL_EXAM_HALLS: string = 'EXAMS_INTERNAL_HALLS';
  static INTERNAL_EXAM_HALLS_ADD: string = 'EXAMS_INTERNAL_HALLS_ADD';
  static INTERNAL_EXAM_HALLS_UPDATE: string = 'EXAMS_INTERNAL_HALLS_UPDATE';
  static INTERNAL_EXAM_HALLS_STATUS: string = 'EXAMS_INTERNAL_HALLS_STATUS';
  static INTERNAL_EXAM_HALLS_STUDENT: string = 'EXAMS_INTERNAL_HALLS_STUDENT';
  static INTERNAL_EXAM_HALLS_INVIGILATOR: string = 'EXAMS_INTERNAL_HALLS_INVIGILATOR';
  static INTERNAL_EXAM_HALLS_DELETE: string = 'EXAMS_INTERNAL_HALLS_DELETE';
  static INTERNAL_EXAM_RESULT: string = 'EXAMS_INTERNAL_RESULT';
  static INTERNAL_EXAM_RESULT_VIEW = 'EXAMS_INTERNAL_RESULT_VIEW';
  static INTERNAL_EXAM_UPLOAD: string = 'EXAMS_INTERNAL_RESULT_UPLOAD';

  static INTERNAL_EXAM_HALLS_ASSIGN_STUDENTS = 'EXAMS_INTERNAL_EXAM_HALLS_ASSIGN_STUDENTS';
  static INTERNAL_EXAM_SCHEDULER_FETCH_QUESTIONS = 'EXAMS_INTERNAL_EXAM_SCHEDULER_FETCH_QUESTIONS';


  static FINAL: string = 'EXAMS_FINAL';
  static FINAL_EXAM_SESSION: string = 'EXAMS_FINAL_SESSIONS';
  static FINAL_EXAM_SESSION_ADD: string = 'EXAMS_FINAL_SESSIONS_ADD';
  static FINAL_EXAM_SESSION_UPDATE: string = 'EXAMS_FINAL_SESSIONS_UPDATE';
  static FINAL_EXAM_SESSION_STATUS: string = 'EXAMS_FINAL_SESSIONS_STATUS';
  static FINAL_EXAM_SESSION_DELETE: string = 'EXAMS_FINAL_SESSIONS_DELETE';
  static FINAL_EXAM_QUESTIONS: string = 'EXAMS_FINAL_QUESTIONS';
  static FINAL_EXAM_QUESTIONS_UPLOAD: string = 'EXAMS_FINAL_QUESTIONS_UPLOAD';
  static FINAL_EXAM_ELIGIBLE_STUDENTS: string = 'EXAMS_FINAL_ELIGIBLE_STUDENTS';
  static FINAL_EXAM_ELIGIBLE_ASSIGN: string = 'EXAMS_FINAL_ELIGIBLE_ASSIGN';
  static FINAL_EXAM_SCHEDULER: string = 'EXAMS_FINAL_SCHEDULER';
  static FINAL_EXAM_SCHEDULER_ADD: string = 'EXAMS_FINAL_SCHEDULER_ADD';
  static FINAL_EXAM_SCHEDULER_FETCH: string = 'EXAMS_FINAL_SCHEDULER_FETCH';
  static FINAL_EXAM_SCHEDULER_UPDATE: string = 'EXAMS_FINAL_SCHEDULER_UPDATE';
  static FINAL_EXAM_SCHEDULER_STATUS: string = 'EXAMS_FINAL_SCHEDULER_STATUS';
  static FINAL_EXAM_SCHEDULER_DELETE: string = 'EXAMS_FINAL_SCHEDULER_DELETE';
  static FINAL_EXAM_HALLS: string = 'EXAMS_FINAL_HALLS';
  static FINAL_EXAM_HALLS_ADD: string = 'EXAMS_FINAL_HALLS_ADD';
  static FINAL_EXAM_HALLS_UPDATE: string = 'EXAMS_FINAL_HALLS_UPDATE';
  static FINAL_EXAM_HALLS_STATUS: string = 'EXAMS_FINAL_HALLS_STATUS';
  static FINAL_EXAM_HALLS_STUDENT: string = 'EXAMS_FINAL_HALLS_STUDENT';
  static FINAL_EXAM_HALLS_INVIGILATOR: string = 'EXAMS_FINAL_HALLS_INVIGILATOR';
  static FINAL_EXAM_HALLS_DELETE: string = 'EXAMS_FINAL_HALLS_DELETE';
  static FINAL_EXAM_RESULT: string = 'EXAMS_FINAL_RESULT';
  static FINAL_EXAM_RESULT_VIEW: string = 'EXAMS_FINAL_RESULT_VIEW';
  static FINAL_EXAM_RESULT_UPLOAD: string = 'EXAMS_FINAL_RESULT_UPLOAD';

  static FINAL_EXAM_HALLS_ASSIGN_STUDENTS = 'EXAMS_FINAL_EXAM_HALLS_ASSIGN_STUDENTS';
  static FINAL_EXAM_SCHEDULER_FETCH_QUESTIONS = 'EXAMS_FINAL_EXAM_SCHEDULER_FETCH_QUESTIONS';

  // Docs
  static DOCS: String = 'DOCS';
  static DOCS_MARKSHEET: string = 'DOCS_MARKSHEET';
  static DOCS_MARKSHEET_DOCUMENT: string = 'DOCS_MARKSHEET_DOCUMENT';
  static DOCS_MARKSHEET_PAYMENT_PROCESS: string = 'DOCS_MARKSHEET_PAYMENT_PROCESS';
  static DOCS_MARKSHEET_PAYMENT_VALIDATE: string = 'DOCS_MARKSHEET_PAYMENT_VALIDATE';
  static DOCS_MARKSHEET_DOCUMENT_VALIDATE: string = 'DOCS_MARKSHEET_DOCUMENT_VALIDATE';
  static DOCS_MARKSHEET_PRINT_STATUS: string = 'DOCS_MARKSHEET_PRINT_STATUS';
  static DOCS_MARKSHEET_CONSIGNMENT: string = 'DOCS_MARKSHEET_CONSIGNMENT';
  static DOCS_MARKSHEET_DELIVERY: string = 'DOCS_MARKSHEET_DELIVERY';
  static DOCS_MARKSHEET_DETAIL: string = 'DOCS_MARKSHEET_DETAIL';
  static DOCS_TRANSCRIPT: string = 'DOCS_TRANSCRIPT';
  static DOCS_TRANSCRIPT_DOCUMENT: string = 'DOCS_TRANSCRIPT_DOCUMENT';
  static DOCS_TRANSCRIPT_PAYMENT_PROCESS: string = 'DOCS_TRANSCRIPT_PAYMENT_PROCESS';
  static DOCS_TRANSCRIPT_PAYMENT_VALIDATE: string = 'DOCS_TRANSCRIPT_PAYMENT_VALIDATE';
  static DOCS_TRANSCRIPT_DOCUMENT_VALIDATE: string = 'DOCS_TRANSCRIPT_DOCUMENT_VALIDATE';
  static DOCS_TRANSCRIPT_PRINT_STATUS: string = 'DOCS_TRANSCRIPT_PRINT_STATUS';
  static DOCS_TRANSCRIPT_CONSIGNMENT: string = 'DOCS_TRANSCRIPT_CONSIGNMENT';
  static DOCS_TRANSCRIPT_DELIVERY: string = 'DOCS_TRANSCRIPT_DELIVERY';
  static DOCS_TRANSCRIPT_DETAIL: string = 'DOCS_TRANSCRIPT_DETAIL';
  static DOCS_ORIGINAL_DEGREE: string = 'DOCS_ORIGINAL_DEGREE';
  static DOCS_ORIGINAL_DEGREE_DOCUMENT: string = 'DOCS_ORIGINAL_DEGREE_DOCUMENT';
  static DOCS_ORIGINAL_DEGREE_PAYMENT_PROCESS: string = 'DOCS_ORIGINAL_DEGREE_PAYMENT_PROCESS';
  static DOCS_ORIGINAL_DEGREE_PAYMENT_VALIDATE: string = 'DOCS_ORIGINAL_DEGREE_PAYMENT_VALIDATE';
  static DOCS_ORIGINAL_DEGREE_DOCUMENT_VALIDATE: string = 'DOCS_ORIGINAL_DEGREE_DOCUMENT_VALIDATE';
  static DOCS_ORIGINAL_DEGREE_PRINT_STATUS: string = 'DOCS_ORIGINAL_DEGREE_PRINT_STATUS';
  static DOCS_ORIGINAL_DEGREE_CONSIGNMENT: string = 'DOCS_ORIGINAL_DEGREE_CONSIGNMENT';
  static DOCS_ORIGINAL_DEGREE_DELIVERY: string = 'DOCS_ORIGINAL_DEGREE_DELIVERY';
  static DOCS_ORIGINAL_DEGREE_DETAIL: string = 'DOCS_ORIGINAL_DEGREE_DETAIL';
  static DOCS_NO_DUES_SLIP: string = 'DOCS_NO_DUE_SLIP'
  static DOCS_NO_DUES_SLIP_GENERATE: string = 'DOCS_NO_DUE_SLIP_GENERATE'
  static DOCS_NO_DUES_SLIP_UPDATE: string = 'DOCS_NO_DUE_SLIP_UPDATE'
  static DOCS_NO_DUES_SLIP_DETAIL: string = 'DOCS_NO_DUE_SLIP_DETAIL'


  // Utilities
  static UTILITIES: string = 'UTILITIES';
  static UTILITIES_HOSTEL: string = 'UTILITIES_HOSTELS';
  static UTILITIES_HOSTEL_ADD: string = 'UTILITIES_HOSTELS_ADD';
  static UTILITIES_HOSTEL_ADD_ROOM: string = 'UTILITIES_HOSTELS_ADD_ROOM';
  static UTILITIES_HOSTEL_ADD_MENU: string = 'UTILITIES_HOSTELS_ADD_MENU';
  static UTILITIES_HOSTEL_UPDATE: string = 'UTILITIES_HOSTELS_UPDATE';
  static UTILITIES_HOSTEL_STATUS: string = 'UTILITIES_HOSTELS_STATUS';
  static UTILITIES_HOSTEL_DELETE: string = 'UTILITIES_HOSTELS_DELETE';
  static UTILITIES_VEHICLE: string = 'UTILITIES_VEHICLES';
  static UTILITIES_VEHICLE_ADD: string = 'UTILITIES_VEHICLES_ADD';
  static UTILITIES_VEHICLE_ADD_VEHICLE: string = 'UTILITIES_VEHICLES_ADD_VEHICLE';
  static UTILITIES_VEHICLE_ADD_ROUTE: string = 'UTILITIES_VEHICLES_ADD_ROUTE';
  static UTILITIES_VEHICLE_UPDATE: string = 'UTILITIES_VEHICLES_UPDATE';
  static UTILITIES_VEHICLE_STATUS: string = 'UTILITIES_VEHICLES_STATUS';
  static UTILITIES_VEHICLE_DELETE: string = 'UTILITIES_VEHICLES_DELETE';
  static UTILITIES_HOLIDAY: string = 'UTILITIES_HOLIDAYS';
  static UTILITIES_HOLIDAY_ADD: string = 'UTILITIES_HOLIDAYS_ADD';
  static UTILITIES_HOLIDAY_UPDATE: string = 'UTILITIES_HOLIDAYS_UPDATE';
  static UTILITIES_HOLIDAY_DELETE: string = 'UTILITIES_HOLIDAYS_DELETE';
  static UTILITIES_MISCELLANEOUS: string = 'UTILITIES_MISCELLANEOUS';
  static UTILITIES_MISCELLANEOUS_ADD: string = 'UTILITIES_MISCELLANEOUS_ADD';
  static UTILITIES_MISCELLANEOUS_UPDATE: string = 'UTILITIES_MISCELLANEOUS_UPDATE';
  static UTILITIES_MISCELLANEOUS_STATUS: string = 'UTILITIES_MISCELLANEOUS_STATUS';
  static UTILITIES_MISCELLANEOUS_DELETE: string = 'UTILITIES_MISCELLANEOUS_DELETE';
  static UTILITIES_SCHOLARSHIP: string = 'UTILITIES_SCHOLARSHIP';
  static UTILITIES_SCHOLARSHIP_ADD: string = 'UTILITIES_SCHOLARSHIP_ADD';
  static UTILITIES_SCHOLARSHIP_VIEW: string = 'UTILITIES_SCHOLARSHIP_VIEW';
  static UTILITIES_SCHOLARSHIP_UPDATE: string = 'UTILITIES_SCHOLARSHIP_UPDATE';
  static UTILITIES_SCHOLARSHIP_STATUS: string = 'UTILITIES_SCHOLARSHIP_STATUS';
  static UTILITIES_SCHOLARSHIP_DELETE: string = 'UTILITIES_SCHOLARSHIP_DELETE';
  static UTILITIES_REPORT: string = 'UTILITIES_REPORTS';

  // Alumni
  static ALUMNI: string = 'ALUMNIS';
  static ALUMNI_LIST: string = 'ALUMNIS_LIST';

  // Support Ticket
  static SUPPORT_TICKET: string = 'SUPPORTS_TICKETS';
  static STUDENT_SUPPORT_TICKET: string = 'SUPPORTS_TICKETS_STUDENT_TICKETS';
  static STUDENT_REMARK: string = 'SUPPORTS_TICKETS_STUDENT_TICKETS_REMARK';
  static STUDENT_DELETE: string = 'SUPPORTS_TICKETS_STUDENT_TICKETS_DELETE';
  static STUDENT_UPLOAD: string = 'SUPPORTS_TICKETS_STUDENT_TICKETS_UPLOAD';
  static STUDENT_VIEW: string = 'SUPPORTS_TICKETS_STUDENT_TICKETS_VIEW';
  static CENTER_SUPPORT_TICKET: string = 'SUPPORTS_TICKETS_CENTER_TICKETS';
  static CENTER_REMARK: string = 'SUPPORTS_TICKETS_CENTER_TICKETS_REMARK';
  static CENTER_SUPPORT_TICKET_DELETE: string = 'SUPPORTS_TICKETS_CENTER_TICKETS_DELETE';
  static CENTER_UPLOAD: string = 'SUPPORTS_TICKETS_CENTER_TICKETS_UPLOAD';
  static CENTER_VIEW: string = 'SUPPORTS_TICKETS_CENTER_TICKETS_VIEW';
  static SUPPORT_TICKET_REPORT: string = 'SUPPORTS_TICKETS_REPORTS';

  // Allegations
  static ALLEGATIONS: string = 'ALLEGATIONS';
  static ADD_ALLEGATIONS: string = 'ALLEGATIONS_ADD';
  static LIST_ALLEGATIONS: string = 'ALLEGATIONS_LIST';
  static UPDATE_ALLEGATIONS: string = 'ALLEGATIONS_LIST_UPDATE';
  static DETAIL_ALLEGATIONS: string = 'ALLEGATIONS_LIST_DETAIL';
  static ALLEGATION_DETAILS = 'ALLEGATIONS_LIST_DELETE';
  static ALLEGATION_TRANSACTIONS: string = 'ALLEGATIONS_TRANSACTIONS';
  static ALLEGATION_TRANSACTIONS_STATUS: string = 'ALLEGATIONS_TRANSACTIONS_STATUS';

  // Tasks
  static TASKS: string = 'TASKS';
  static TASKS_LIST: string = 'TASKS_LIST';
  static TASKS_LIST_ADD: string = 'TASKS_LIST_ADD';
  static TASKS_LIST_ASSIGN: string = 'TASKS_LIST_ASSIGN';
  static TASKS_LIST_UPDATE: string = 'TASKS_LIST_UPDATE';
  static TASKS_LIST_DELETE: string = 'TASKS_LIST_DELETE';
  static TASKS_LIST_DETAIL: string = 'TASKS_LIST_DETAIL';

  // Notification
  static NOTIFICATION: string = 'NOTIFICATIONS';
  static STUDENT_NOTIFICATION: string = 'NOTIFICATIONS_STUDENT_NOTIFICATION';
  static STUDENT_NOTIFICATION_ADD: string = 'NOTIFICATIONS_STUDENT_ADD';
  static CENTER_NOTIFICATION: string = 'NOTIFICATIONS_CENTER_NOTIFICATION';
  static CENTER_NOTIFICATION_ADD: string = 'NOTIFICATIONS_CENTER_NOTIFICATION_ADD';
  static NOTIFICATION_REPORT: string = 'NOTIFICATIONS_REPORTS';
}
