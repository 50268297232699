import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'IndianNumber'
})
export class IndianNumberPipe implements PipeTransform {
  transform(value: number | string, safe = true): string | null {
    if (!safe && (value === null || value === undefined)) {
      return null;
    }

    // Ensure the input is a valid number
    const numericValue = Number(value);
    if (isNaN(numericValue)) {
      return null; // Return null if the value cannot be converted to a number
    }

    // Round off to two decimal places for decimal values
    const roundedValue = numericValue.toFixed(2);

    const result = roundedValue.split('.');
    let lastThree = result[0].substring(result[0].length - 3);
    const otherNumbers = result[0].substring(0, result[0].length - 3);

    if (otherNumbers !== '' && otherNumbers !== '-') {
      lastThree = ',' + lastThree;
    }

    let output = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree;

    if (result.length > 1) {
      output += '.' + result[1]; // Ensure only two digits after the decimal point
    }

    return output;
  }
}
