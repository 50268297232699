import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MenuItem} from '../interfaces/menu-item.interface';
import {trackById} from '@vex/utils/track-by';
import {VexPopoverRef} from '@vex/components/vex-popover/vex-popover-ref';
import {Router, RouterLink} from '@angular/router';
import {MatOption, MatRippleModule} from '@angular/material/core';
import {NgClass, NgFor, NgIf, NgStyle} from '@angular/common';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {StorageService} from '../../../../../shared/services/storage.service';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';
import {GlobalService} from '../../../../../shared/services/global.service';
import {AlertService} from '../../../../../shared/services/alert.service';
import {AuthService} from '../../../../../shared/services/auth.service';
import {DataService} from '../../../../../shared/services/data.service';
import {ImageCroppedEvent, ImageCropperComponent} from 'ngx-image-cropper';
import {SharedModule} from "../../../../../shared/shared.module";

export interface OnlineStatus {
  id: 'online' | 'away' | 'dnd' | 'offline';
  label: string;
  icon: string;
  colorClass: string;
}

@Component({
  selector: 'vex-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatMenuModule,
    NgFor,
    MatRippleModule,
    RouterLink,
    NgClass,
    NgIf,
    NgStyle,
    MatDialogModule,
    MatCardModule,
    ImageCropperComponent,
    MatOption,
    SharedModule
  ],
  providers: [GlobalService, AlertService, AuthService, DataService]
})
export class ToolbarUserDropdownComponent implements OnInit {
  @ViewChild('imageUpdateDialog') imageUpdateDialog: TemplateRef<any>;
  items: MenuItem[] = [
    {
      id: '1',
      icon: 'mat:account_circle',
      label: 'My Profile',
      description: 'Personal Information',
      colorClass: 'text-teal-600',
      route: '/profile'
    },
    {
      id: '2',
      icon: 'mat:table_chart',
      label: 'Orders',
      description: 'Purchased Plans & Invoices',
      colorClass: 'text-purple-600',
      route: '/orders',

    }
  ];
  user: any;
  profileIcons: any = [];
  resultImage: any;
  statuses: OnlineStatus[] = [
    {
      id: 'online',
      label: 'Online',
      icon: 'mat:check_circle',
      colorClass: 'text-green-600'
    },
    {
      id: 'away',
      label: 'Away',
      icon: 'mat:access_time',
      colorClass: 'text-orange-600'
    },
    {
      id: 'dnd',
      label: 'Do not disturb',
      icon: 'mat:do_not_disturb',
      colorClass: 'text-red-600'
    },
    {
      id: 'offline',
      label: 'Offline',
      icon: 'mat:offline_bolt',
      colorClass: 'text-gray-600'
    }
  ];

  activeStatus: OnlineStatus = this.statuses[0];
  trackById = trackById;
  previewImage: string | ArrayBuffer | null = '';
  maxSize: number = 2 * 1024 * 1024;
  imageType: any;

  imageChangedEvent = null;
  croppedImage: any;
  sendCroppedImage: any;

  constructor(
    private cd: ChangeDetectorRef,
    private router: Router,
    private popoverRef: VexPopoverRef<ToolbarUserDropdownComponent>,
    public dialog: MatDialog,
    private authService: AuthService,
  ) {
  }

  ngOnInit(): void {
    this.authService.self().subscribe(data => {
      if (data && data.id) {
        this.user = data;
        this.resultImage = data.profile_image;
        this.cd.detectChanges();
      }
    });
  }

  setStatus(status: OnlineStatus): void {
    this.activeStatus = status;
    this.cd.markForCheck();
  }

  logout(): void {
    this.authService.logout().subscribe(data => {
      this.popoverRef.close();
      StorageService.clearAll();
      this.router.navigateByUrl('/auth/login');
    });
  }

  close(): void {
    this.popoverRef.close();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(this.imageUpdateDialog, {
      width: '40vw',
      minWidth: '350px',
      data: {maxSize: this.maxSize}
    });
  }

  async fileChangeEvent(event: any, type: any): Promise<void> {
    const params: {} = {
      'type': type,
      'fileType': 'BASE64',
    };
  }

  uploadProfileImage(data: any): void {
    const params: {} = {
      profile_image: data.id
    }
    this.authService.updateProfile(params).subscribe(response => {
      this.dialog.closeAll();
      this.resultImage = data.url;
      this.cd.detectChanges();
    });
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    if (event.dataTransfer && event.dataTransfer.files.length) {
      const file = event.dataTransfer.files[0];
      this.previewFile(file);
    }
  }

  previewFile(file: File): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.previewImage = reader.result;
    };
    reader.readAsDataURL(file);
  }

  getImage(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.objectUrl;
    this.convertBlobToBase64(event.blob);
  }

  convertBlobToBase64(blob: Blob): void {
    const reader = new FileReader();
    reader.onloadend = () => {
      this.sendCroppedImage = reader.result as string;
    };
    reader.readAsDataURL(blob);
  }

  imageLoaded(event: any): void {
  }

  cropperReady(): void {
  }

  loadImageFailed(): void {
  }

  goTo(url: string): void {
    this.router.navigateByUrl(url);
  }
}
