import {Injectable} from '@angular/core';
import {AppConstants} from "./app-constant";

@Injectable()
export class Helper {
  static capitalize(string: any) {
    string = string.toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  static capitalizeFirstWord(string: any) {
    let splitStr = string.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }

  static removeObject(arr, attr, value) {
    let i = arr.length;
    while (i--) {
      if (arr[i]
        && arr[i].hasOwnProperty(attr)
        && (arguments.length > 2 && arr[i][attr] === value)) {
        arr.splice(i, 1);
      }
    }
    return arr;
  }

  static mapTwoArrays(primaryArr, secondaryArr, primaryArrkey, secondaryArrKey, statusKey) {
    if (primaryArr && primaryArr.length > 0) {
      primaryArr.forEach((item: any) => {
        item[statusKey] = false;
        if (secondaryArr && secondaryArr.length > 0) {
          secondaryArr.forEach((dt: any) => {
            if (dt && dt[secondaryArrKey] == item[primaryArrkey]) {
              item[statusKey] = true;
            }
          });
        }
      });
      return primaryArr;
    } else {
      return [];
    }
  }

  static updateArray(primaryArr, changedArr, id) {
    if (primaryArr && primaryArr.length > 0) {
      primaryArr.forEach((items: any, key: any) => {
        if (items.id === id) {
          for (const obj in changedArr) {
            primaryArr[key][obj] = changedArr[obj];
          }
        }
      });
    }
    return primaryArr;
  }

  static formatDate(date: any): any {
    let d: any = new Date(date),
      month: any = '' + (d.getMonth() + 1),
      day: any = '' + d.getDate(),
      year: any = d.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  }

  static uniqueArray(array: any, key: any): any {
    const arr: Set<any> = new Set();
    return array.filter((item: { [x: string]: any; }) => {
      const value = item[key];
      if (arr.has(value)) {
        return false;
      } else {
        arr.add(value);
        return true;
      }
    });
  }

  static addDaysWithDate(date, days) {
    let currentDate = new Date(date);
    currentDate.setDate(currentDate.getDate() + days);
    let year = currentDate.getFullYear();
    let month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    let day = currentDate.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  static removeDuplicates(arr, key) {
    const seen = new Set();
    const uniqueArr = [];

    arr.forEach(item => {
      if (!seen.has(item[key])) {
        seen.add(item[key]);
        uniqueArr.push(item);
      }
    });
    return uniqueArr;
  }

  static filterArray(array, conditions) {
    return array.filter(item => {
      return Object.keys(conditions).every(key => {
        return item[key] === conditions[key];
      });
    });
  }
  static findCommonElements(arr1, arr2) {
    const set = new Set(arr1);
    return arr2.some(element => set.has(element));
  }
  static workingDaysCheck(workingDays: any) {
    return workingDays && !Object.values(workingDays).every(day => day === 'WEEKLY_OFF');
  }

  static charge_type(type: any) {
    if(type === "ONE_TIME")
      return "One Time";
    else
      return "Yearly";
  }

  static room_type(room: any) {
    if(room === "NO_AC")
      return "Non AC";
    else
      return "AC";
  }

  static scholarshipType(type: any) {
    if(type === "GOVERNMENT") return "Government";
    else return "Non Government";
  }

  static appliedSession(pcbs: any, session: any) {
    return pcbs.filter((pcb: any) => pcb?.applied_session && JSON.parse(pcb.applied_session).includes(session));
  }

  static actualFeeYear(student: any, year: any) {
    const year_name: string = ['first', 'second', 'third', 'fourth', 'fifth', 'sixth'][year];
    let total_fee: number = 0;
    total_fee += AppConstants.feeKeys.reduce((sum: any, key: any) => sum + Number(student[year_name + '_year_' + key]), 0);
    const year_names: string = ['ist', 'second', 'third', 'fourth', 'fifth', 'sixth'][year];
    const hostel_component: any = JSON.parse(student[year_names+'_year_hostel_components']);
    total_fee += (hostel_component && hostel_component['hostel'] && hostel_component['hostel'].actual_room_charge) ? Number(hostel_component['hostel'].actual_room_charge) : 0;
    total_fee += (hostel_component && hostel_component['hostel'] && hostel_component['hostel'].actual_mess_charge) ? Number(hostel_component['hostel'].actual_mess_charge) : 0;
    total_fee += (hostel_component && hostel_component['hostel'] && hostel_component['hostel'].actual_caution_charge) ? Number(hostel_component['hostel'].actual_caution_charge) : 0;
    total_fee += (hostel_component && hostel_component['hostel'] && hostel_component['hostel'].actual_security_charge) ? Number(hostel_component['hostel'].actual_security_charge) : 0;
    const vehicle_component: any = JSON.parse(student[year_names+'_year_vehicle_components']);
    total_fee += (vehicle_component && vehicle_component['vehicle'] && vehicle_component['vehicle'].actual_vehicle_charges) ? Number(vehicle_component['vehicle'].actual_vehicle_charges) : 0;
    return total_fee;
  }

  static discountFeeYear(student: any, year: any) {
    const year_name: string = ['ist', 'second', 'third', 'fourth', 'fifth', 'sixth'][year];
    const getComponentFee = (component: any, key: string) => component && component[key] ? Number(component[key]) : 0;
    if (student && student[year_name + '_year_fee_components']) {
      const fee_component: any = JSON.parse(student[year_name + '_year_fee_components']);
      const hostel_component: any = JSON.parse(student[year_name + '_year_hostel_components']);
      const vehicle_component: any = JSON.parse(student[year_name + '_year_vehicle_components']);
      let total_fee: number = 0;
      total_fee += AppConstants.feeKeys.reduce((sum, key) => sum + getComponentFee(fee_component, key), 0);
      if (hostel_component && hostel_component['hostel']) {
        total_fee += ['room_charge', 'mess_charge', 'caution_charge', 'security_charge'].reduce((sum, key) => sum + getComponentFee(hostel_component['hostel'], key), 0);
      }
      if (vehicle_component && vehicle_component['vehicle']) {
        total_fee += getComponentFee(vehicle_component['vehicle'], 'vehicle_charges');
      }
      return total_fee;
    }
    return 0;
  }

  static actualComponentFeeYear(student: any, year: any) {
    const year_name: string = ['first', 'second', 'third', 'fourth', 'fifth', 'sixth'][year];
    if (student[`${year_name}_year_tuition_fee`]) {
      return AppConstants.feeKeys.reduce((sum: any, key: any) => sum + Number(student[`${year_name}_year_${key}`]) || 0, 0);
    } else {
      return false;
    }
  }
}
