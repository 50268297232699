import {Component, OnInit} from '@angular/core';
import { HeaderComponent } from '../../../../landing/header/header.component';
import {
  DatePipe,
  NgClass,
  NgForOf,
  NgIf,
  TitleCasePipe
} from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MeetingsService } from '../../../../shared/services/meetings.service';
import { environment } from '../../../../../environments/environment';
import moment from 'moment-timezone';
import { PusherService } from '../../../../shared/services/pusher.service';

@Component({
  selector: 'vex-visitor-token',
  standalone: true,
  imports: [HeaderComponent, NgClass, NgIf, NgForOf, TitleCasePipe, DatePipe],
  templateUrl: './visitor-token.component.html',
  styleUrl: './visitor-token.component.scss'
})
export class VisitorTokenComponent implements OnInit {
  meeting: any;
  isExpired = false;
  remainingTime: any;

  constructor(
    private route: ActivatedRoute,
    private meetingService: MeetingsService,
    private pusherService: PusherService
  ) {}

  ngOnInit() {
    this.refresh();

    this.pusherService.subscribe(
      'meeting-channel',
      'meeting-event',
      (data: any) => {
        if (data.meeting_id === this.meeting.meeting_id) {
          this.refresh();
        }
      }
    );
  }

  refresh() {
    this.route.params.subscribe((data: any) => {
      this.meetingService.visitorToken(data.id).subscribe((data) => {
        if (data && data.id) {
          this.meeting = data;
          if (
            this.meeting?.meeting_end_time ||
            this.meeting?.status === 'REJECTED'
          ) {
            let expiry: any;
            if (this.meeting?.meeting_end_time) {
              expiry = moment(this.meeting?.meeting_end_time)
                .add(15, 'minutes')
                .format('YYYY-MM-DD HH:mm:ss');
            } else if (this.meeting?.status === 'REJECTED') {
              expiry = moment(this.meeting?.updated_at)
                .tz('Asia/Kolkata')
                .add(15, 'minutes')
                .format('YYYY-MM-DD HH:mm:ss');
            }
            const currentTime: any = moment(
              `${this.meeting.current_date}T${this.meeting.current_time}Z`
            )
              .tz('UTC')
              .format('YYYY-MM-DD HH:mm:ss');
            const expiryMoment = moment(expiry, 'YYYY-MM-DD HH:mm:ss');
            const currentMoment = moment(currentTime, 'YYYY-MM-DD HH:mm:ss');
            if (currentMoment.isAfter(expiryMoment)) {
              this.isExpired = true;
            } else {
              this.startTimer(expiryMoment.diff(currentMoment));
            }
          }
        }
      });
    });
  }

  startTimer(duration: number): void {
    const interval = setInterval(() => {
      duration -= 1000;
      if (duration <= 0) {
        this.remainingTime = null;
        this.isExpired = true;
        clearInterval(interval);
      } else {
        this.remainingTime = this.formatDuration(duration);
      }
    }, 1000);
  }

  formatDuration(duration: number): string {
    const seconds = Math.floor((duration / 1000) % 60);
    const minutes = Math.floor((duration / (1000 * 60)) % 60);
    const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  protected readonly JSON = JSON;
  protected readonly environment = environment;
}
