import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';
import {StorageService} from '../services/storage.service';

@Injectable()
export class AppUrl {
  static get ADMIN_URL(): string {
    return environment.appUrl + 'admin/';
  }

  static get LOGIN(): string {
    return AppUrl.ADMIN_URL + 'login';
  }

  static get SELF(): string {
    return AppUrl.ADMIN_URL + 'self';
  }

  static UPDATE_PASSWORD(): string {
    return AppUrl.ADMIN_URL + 'update-password';
  }

  static UPDATE_SELF(): string {
    return AppUrl.ADMIN_URL + 'update-self';
  }

  static LOGOUT(): string {
    return AppUrl.ADMIN_URL + 'logout';
  }

  static VERIFY_OTP(): string {
    return AppUrl.ADMIN_URL + 'verify-login';
  }

  static RESEND_OTP(): string {
    return AppUrl.ADMIN_URL + 'resend-otp';
  }

  static UPDATE_PROFILE_IMAGE(): string {
    return AppUrl.ADMIN_URL + 'update-profile-image';
  }

  static FILE_UPLOAD(): string {
    return AppUrl.ADMIN_URL + 'file-upload';
  }

  static ALLEGATION_DOCS_UPLOAD(): string {
    return AppUrl.ADMIN_URL + 'allegation-file-upload';
  }

  static MULTIPLE_FILE_UPLOAD(): string {
    return AppUrl.ADMIN_URL + 'file-multiple-upload';
  }

  static COUNTRY(): string {
    return AppUrl.ADMIN_URL + 'countries';
  }

  static USERS(): string {
    return AppUrl.ADMIN_URL + 'user';
  }

  static STATES(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'states/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'states';
  }

  static ADMIN_COUNTS(): string {
    return AppUrl.ADMIN_URL + 'admin-counts';
  }

  static ADMIN_WISE_ADMISSIONS(): string {
    return AppUrl.ADMIN_URL + 'admin-wise-admissions';
  }

  static ADMIN(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'admin/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'admin';
    }
  }

  static ALLEGATION(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'allegation/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'allegation';
    }
  }

  static MEETINGS(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'meeting-person/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'meeting-person';
    }
  }

  static SCHEDULE_MEETING(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'meeting/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'meeting';
    }
  }

  static MEETINGS_STATUS(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'meeting-person-status/' + unique_id;
  }

  static ADMIN_COUNT(): string {
    return AppUrl.ADMIN_URL + 'admin-count';
  }

  static ADMIN_STATUS(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'admin-status/' + unique_id;
  }

  static TASK_STATUS(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'task-status/' + unique_id;
  }

  static ADMIN_CHANGE_PASSWORD(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'admin-password/' + unique_id;
  }

  static ADMIN_PROFILE_IMAGE(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'admin-profile-image/' + unique_id;
  }

  static EMPLOYEE(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'employee/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'employee';
    }
  }

  static GET_EMPLOYEES(): string {
    return AppUrl.ADMIN_URL + 'employees';
  }

  static EMPLOYEE_COUNT(): string {
    return AppUrl.ADMIN_URL + 'employee-count';
  }

  static EMPLOYEE_STATUS(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'employee-status/' + unique_id;
  }

  static EMPLOYEE_CHANGE_PASSWORD(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'employee-password/' + unique_id;
  }

  static EMPLOYEE_PROFILE_IMAGE(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'employee-profile-image/' + unique_id;
  }

  static EMPLOYEE_GOVT_PHOTO(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'employee-govt-photo-id/' + unique_id;
  }

  static EMPLOYEE_PAN_IMAGE(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'employee-pan-image/' + unique_id;
  }

  static EMPLOYEE_TENTH_CERTIFICATE(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'employee-tenth-certificate/' + unique_id;
  }

  static EMPLOYEE_TWELTH_CERTIFICATE(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'employee-twelth-certificate/' + unique_id;
  }

  static EMPLOYEE_GRADUATION_CERTIFICATE(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'employee-graduation-certificate/' + unique_id;
  }

  static EMPLOYEE_POST_GRADUATION_CERTIFICATE(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'employee-post-graduation-certificate/' + unique_id;
  }

  static EMPLOYEE_OTHER_QUALIFICATION_CERTIFICATE(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'employee-other-qualification-certificate/' + unique_id;
  }

  static EMPLOYEE_HIGHEST_QUALIFICATION_CERTIFICATE(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'employee-highest-qualification-certificate/' + unique_id;
  }

  static EMPLOYEE_PERSONAL_INFO(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'employee-personal-info/' + unique_id;
  }

  static EMPLOYEE_BANK_INFO(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'employee-bank-info/' + unique_id;
  }

  static EMPLOYEE_ALLOWANCE_INFO(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'employee-allowance-info/' + unique_id;
  }

  static EMPLOYEE_OTHER_INFO(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'employee-other-info/' + unique_id;
  }

  static EMPLOYEE_SALARY_INFO(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'employee-salary-info/' + unique_id;
  }

  static EMPLOYEE_PAYROLL(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'employee-payroll/' + unique_id;
  }

  static ALL_EMPLOYEE_PAYROLL(): string {
    return AppUrl.ADMIN_URL + 'employee-payroll';
  }

  static DOWNLOAD_EMPLOYEE_PAYROLL(): string {
    return AppUrl.ADMIN_URL + 'download-employee-payroll';
  }

  static EMPLOYEE_HOD(): string {
    return AppUrl.ADMIN_URL + 'hods';
  }

  static EMPLOYEE_ASSIGN_HOD(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'employee-hod/' + uniqueId;
  }

  static EMPLOYEE_EVALUATION(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'employee-evaluation/' + uniqueId;
  }

  static EMPLOYEE_ATTENDANCE(): string {
    return AppUrl.ADMIN_URL + 'employee-attendance';
  }

  static EMPLOYEE_SELF_ATTENDANCE(): string {
    return AppUrl.ADMIN_URL + 'employee-attendance-self';
  }

  static EMPLOYEE_ATTENDANCE_COUNT(): string {
    return AppUrl.ADMIN_URL + 'employee-attendance-count';
  }

  static CENTER(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'center/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'center';
    }
  }

  static CENTER_COUNT(): string {
    return AppUrl.ADMIN_URL + 'center-count';
  }

  static CENTER_STATUS(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'center-status/' + unique_id;
  }

  static CENTER_CHANGE_PASSWORD(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'center-password/' + unique_id;
  }

  static CENTER_WALLET_RECHARGE(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'center-wallet-recharge/' + unique_id;
  }

  static CENTER_SHARING_PERCENTAGE_PCB(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'center-sharing-percentage-pcb/' + unique_id;
  }

  static CENTER_SHARING_PERCENTAGE(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'center-sharing-percentage/' + unique_id;
  }

  static CENTER_SHARING_PERCENTAGE_ALL(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'center-sharing-percentage-all/' + unique_id;
  }

  static CENTER_PROFILE_IMAGE(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'center-profile-image/' + unique_id;
  }

  static CENTER_BRANCHES(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'center-branch/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'center-branch';
    }
  }

  static CENTER_BRANCHES_COUNT(): string {
    return AppUrl.ADMIN_URL + 'center-branch-count';
  }

  static CENTER_BRANCHES_STATUS(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'center-branch-status/' + unique_id;
  }

  static CENTER_BRANCHES_CHANGE_PASSWORD(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'center-branch-password/' + unique_id;
  }

  static CENTER_BRANCHES_WALLET_RECHARGE(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'center-branch-wallet-recharge/' + unique_id;
  }

  static CENTER_BRANCH_SHARING_PERCENTAGE(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'center-branch-sharing-percentage/' + unique_id;
  }

  static DEPARTMENT(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'department/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'department';
    }
  }

  static DEPARTMENT_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'department-status/' + uniqueId;
  }

  static DEPARTMENT_COUNT(): string {
    return AppUrl.ADMIN_URL + 'department-count';
  }

  static DESIGNATION(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'designation/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'designation';
    }
  }

  static DESIGNATION_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'designation-status/' + uniqueId;
  }

  static DESIGNATION_COUNT(): string {
    return AppUrl.ADMIN_URL + 'designation-count';
  }

  static INTERNAL_EXAM_SCHEDULER_FETCH_QUESTIONS(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'internal-exam-scheduler-question/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'internal-exam-scheduler-question';
  }

  static INTERNAL_EXAM_SCHEDULER_ADD_FETCH_QUESTIONS(): string {
    return AppUrl.ADMIN_URL + 'internal-exam-finalize-question';
  }

  static FINAL_EXAM_SCHEDULER_FETCH_QUESTIONS(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'final-exam-scheduler-question/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'final-exam-scheduler-question';
  }

  static FINAL_EXAM_SCHEDULER_ADD_FETCH_QUESTIONS(): string {
    return AppUrl.ADMIN_URL + 'final-exam-finalize-question';
  }

  static INTERNAL_EXAM_SCHEDULE(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'internal-exam-scheduler/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'internal-exam-scheduler';
  }

  static INTERNAL_EXAM_HALLS(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'internal-exam-hall/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'internal-exam-hall';
  }

  static INTERNAL_EXAM_HALL_ASSIGN_STUDENT(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'internal-exam-hall-student/' + uniqueId;
  }

  static FINAL_EXAM_HALL_ASSIGN_STUDENT(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'final-exam-hall-student/' + uniqueId;
  }

  static INTERNAL_EXAM_HALL_ASSIGN_INVIGILATOR(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'internal-exam-hall-invigilator/' + uniqueId;
  }

  static INTERNAL_EXAM_RESULT_STUDENTS(): string {
    return AppUrl.ADMIN_URL + 'internal-exam-result-subjects';
  }

  static FINAL_EXAM_RESULT_STUDENTS(): string {
    return AppUrl.ADMIN_URL + 'final-exam-result-subjects';
  }

  static INTERNAL_EXAM_RESULT(): string {
    return AppUrl.ADMIN_URL + 'internal-exam-result';
  }

  static FINAL_EXAM_RESULT(): string {
    return AppUrl.ADMIN_URL + 'final-exam-result';
  }

  static FINAL_EXAM_HALL_ASSIGN_INVIGILATOR(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'final-exam-hall-invigilator/' + uniqueId;
  }

  static FINAL_EXAM_HALLS(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'final-exam-hall/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'final-exam-hall';
  }

  static FINAL_ELIGIBLE_ASSIGN_STUDENTS(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'eligible-student/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'eligible-student';
  }

  static FINAL_VERIFIED_STUDENTS(): string {
    return AppUrl.ADMIN_URL + 'final-verification';
  }

  static STUDENT_EXCEL_ADD(): string {
    return AppUrl.ADMIN_URL + 'student-upload';
  }

  static FINAL_EXAM_SCHEDULE(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'final-exam-scheduler/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'final-exam-scheduler';
  }

  static INTERNAL_EXAM_SCHEDULE_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'internal-exam-scheduler-status/' + uniqueId;
  }

  static INTERNAL_EXAM_HALL_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'internal-exam-hall-status/' + uniqueId;
  }

  static INTERNAL_EXAM_HALLS_ASSIGN_STUDENTS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'internal-exam-hall-detail/' + uniqueId;
  }

  static FINAL_EXAM_HALLS_ASSIGN_STUDENTS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'final-exam-hall-detail/' + uniqueId;
  }

  static FINAL_EXAM_HALLS_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'final-exam-hall-status/' + uniqueId;
  }

  static FINAL_EXAM_SCHEDULE_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'final-exam-scheduler-status/' + uniqueId;
  }

  static INTERNAL_EXAM_SESSION(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'internal-exam-session/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'internal-exam-session';
  }

  static INTERNAL_EXAM_SESSION_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'internal-exam-session-status/' + uniqueId;
  }

  static FINAL_EXAM_SESSION(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'final-exam-session/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'final-exam-session';
  }

  static FINAL_EXAM_SESSION_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'final-exam-session-status/' + uniqueId;
  }

  static SCHOOL(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'school/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'school';
  }

  static SCHOOL_COUNT(): string {
    return AppUrl.ADMIN_URL + 'school-count';
  }

  static SCHOOL_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'school-status/' + uniqueId;
  }

  static PROGRAM(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'program/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'program';
  }

  static PROGRAM_COUNT(): string {
    return AppUrl.ADMIN_URL + 'program-count';
  }

  static PROGRAM_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'program-status/' + uniqueId;
  }

  static SECTION_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'section-status/' + uniqueId;
  }

  static SECTION_MAPPING_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'pcb-section-status/' + uniqueId;
  }


  static INVENTORY_CATEGORY(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'inventory-category/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'inventory-category';
  }

  static INVENTORY_CATEGORY_COUNT(): string {
    return AppUrl.ADMIN_URL + 'inventory-category-count';
  }

  static INVENTORY_CATEGORY_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'inventory-category-status/' + uniqueId;
  }

  static INVENTORY(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'inventory/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'inventory';
  }

  static INVENTORY_COUNT(): string {
    return AppUrl.ADMIN_URL + 'inventory-count';
  }

  static INVENTORY_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'inventory-status/' + uniqueId;
  }

  static INVENTORY_STOCK(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'inventory-stock/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'inventory-stock';
  }

  static INVENTORY_STOCK_COUNT(): string {
    return AppUrl.ADMIN_URL + 'inventory-stock-count';
  }

  static INVENTORY_STOCK_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'inventory-stock-status/' + uniqueId;
  }

  static INVENTORY_STOCK_INVOICE_ID(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'inventory-stock-invoice-id/' + uniqueId;
  }

  static INVENTORY_TRANSACTION(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.ADMIN_URL + 'inventory-stock-transaction/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'inventory-stock-transaction';
  }

  static INVENTORY_TRANSACTION_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'inventory-stock-transaction-status/' + uniqueId;
  }

  static BOOK_CATEGORY(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'book-category/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'book-category';
  }

  static BOOK_CATEGORY_COUNT(): string {
    return AppUrl.ADMIN_URL + 'book-category-count';
  }

  static BOOK_CATEGORY_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'book-category-status/' + uniqueId;
  }

  static BOOK(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'book/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'book';
  }

  static DELETE_ALLEGATION_SUPPORTIVE_DOCS(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'allegation-file/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'allegation-file';
  }

  static BOOK_IMAGE(uniqueId?: any): string {
    return AppUrl.ADMIN_URL + 'book-image/' + uniqueId;
  }

  static BOOK_COUNT(): string {
    return AppUrl.ADMIN_URL + 'book-count';
  }

  static BOOK_BY_ISBN(): string {
    return AppUrl.ADMIN_URL + 'book-by-isbn';
  }

  static BOOK_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'book-status/' + uniqueId;
  }

  static BOOK_COVER_IMAGE(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'book-cover-image-id/' + uniqueId;
  }

  static BOOK_STOCK(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'book-stock/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'book-stock';
  }

  static BOOK_STOCK_COUNT(): string {
    return AppUrl.ADMIN_URL + 'book-stock-count';
  }

  static BOOK_STOCK_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'book-stock-status/' + uniqueId;
  }

  static BOOK_STOCK_INVOICE_ID(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'book-stock-invoice-id/' + uniqueId;
  }

  static BOOK_TRANSACTION(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.ADMIN_URL + 'book-stock-transaction/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'book-stock-transaction';
  }

  static BOOK_TRANSACTION_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'book-stock-transaction-status/' + uniqueId;
  }

  static BOOK_LIBRARY(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.ADMIN_URL + 'library/' + uniqueId;
    else
      return AppUrl.ADMIN_URL + 'library';
  }

  static BOOK_RETURN(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'library-return/' + uniqueId;
  }

  static BOOK_LIBRARY_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'library-status/' + uniqueId;
  }

  static BOOK_LIBRARY_TRANSACTION(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.ADMIN_URL + 'library-transaction/' + uniqueId;
    else
      return AppUrl.ADMIN_URL + 'library-transaction';
  }

  static BOOK_LIBRARY_TRANSACTION_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'library-transaction-status/' + uniqueId;
  }

  static COURSE(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'course/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'course';
  }

  static COURSE_COUNT(): string {
    return AppUrl.ADMIN_URL + 'course-count';
  }

  static COURSE_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'course-status/' + uniqueId;
  }

  static BRANCH(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'branch/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'branch';
  }

  static BRANCH_COUNT(): string {
    return AppUrl.ADMIN_URL + 'branch-count';
  }

  static BRANCH_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'branch-status/' + uniqueId;
  }

  static SECTION(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'section/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'section';
  }

  static SECTION_MAPPING(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'pcb-section/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'pcb-section';
  }

  static UPLOAD_INTERNAL_QUESTION(): string {
    return AppUrl.ADMIN_URL + 'internal-exam-question-upload';
  }

  static UPLOAD_INTERNAL_RESULT(): string {
    return AppUrl.ADMIN_URL + 'internal-exam-result-upload';
  }

  static UPLOAD_FINAL_RESULT(): string {
    return AppUrl.ADMIN_URL + 'final-exam-result-upload';
  }

  static INTERNAL_QUESTIONS(uniqueId?): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'internal-exam-question/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'internal-exam-question';
  }

  static FINAL_QUESTIONS(uniqueId?): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'final-exam-question/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'final-exam-question';
  }

  static SESSION(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'session/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'session';
  }

  static SESSION_BY_ID(): string {
    return AppUrl.ADMIN_URL + 'session-by-id';
  }

  static DEFAULT_SESSION(uniqueId?): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'default-session/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'default-session';
    }
  }

  static SESSION_COUNT(): string {
    return AppUrl.ADMIN_URL + 'session-count';
  }

  static SESSION_STATUS(uniqueId: any, status: any): string {
    if (status == 'default_session')
      return AppUrl.ADMIN_URL + 'default-session';
    else if (status == 're-registration-session')
      return AppUrl.ADMIN_URL + 're-registration-session/' + uniqueId;
    else if (status == 're-registration-entry-session')
      return AppUrl.ADMIN_URL + 're-registration-entry-session/' + uniqueId;
    else if (status == 'admission-session')
      return AppUrl.ADMIN_URL + 'admission-session/' + uniqueId;
    else if (status == 're-opened-session')
      return AppUrl.ADMIN_URL + 're-opened-session/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'session-status/' + uniqueId;
  }

  static PCB_MAPPING(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'pcb-mapping/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'pcb-mapping';
  }

  static PCB_MAPPING_BY_ID(id: any): string {
    return AppUrl.ADMIN_URL + 'pcb-mapping-by-id/' + id;
  }

  static PCB_MAPPING_HOD(uniqueId?: any): string {
    return AppUrl.ADMIN_URL + 'pcb-mapping-hod/' + uniqueId;
  }

  static PCB_MAPPING_APPLIED_SESSION(uniqueId?: any): string {
    return AppUrl.ADMIN_URL + 'pcb-mapping-applied-session/' + uniqueId;
  }

  static PCB_MAPPING_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'pcb-mapping-status/' + uniqueId;
  }

  static SUBJECT_GROUPING_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'pcb-mapping-subject-group-status/' + uniqueId;
  }


  static PCBS_MAPPING(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'pcbs-mapping/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'pcbs-mapping';
  }

  static PCBS_MAPPING_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'pcbs-mapping-status/' + uniqueId;
  }

  static TIME_TABLE(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'timetable/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'timetable';
  }

  static TASKS(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'task/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'task';
  }

  static TASK_ASSIGNMENT(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'task-assign/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'task-assign';
  }

  static SUBJECT_GROUPING(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'pcb-mapping-subject-group/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'pcb-mapping-subject-group';
  }


  static FEE_STRUCTURE(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'fee-structure/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'fee-structure';
    }
  }

  static FEE_STRUCTURE_PCB(): string {
    return AppUrl.ADMIN_URL + 'fee-structure-by-pcb';
  }

  static SUBJECT(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'subject/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'subject';
  }

  static SUBJECT_COUNT(): string {
    return AppUrl.ADMIN_URL + 'subject-count';
  }

  static SUBJECT_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'subject-status/' + uniqueId;
  }

  static SUBJECT_APPLIED_SESSION(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'subject-applied-session/' + uniqueId;
  }

  static CHAPTER(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'chapters/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'chapters';
  }

  static CHAPTER_COUNT(): string {
    return AppUrl.ADMIN_URL + 'chapters-count';
  }

  static CHAPTER_ASSIGNMENT(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'chapters-assignment/' + uniqueId;
  }

  static CHAPTER_NOTE(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'chapters-note/' + uniqueId;
  }

  static CHAPTER_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'chapters-status/' + uniqueId;
  }

  static STUDENT(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'student/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'student';
    }
  }

  static HOSTEL_STUDENT(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'hostel-student/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'hostel-student';
    }
  }

  static STUDENT_BY_ENROLLMENT(): string {
    return AppUrl.ADMIN_URL + 'student-by-enrollment';
  }

  static VIEW_EMPLOYEE_ATTANDENCE(): string {
    return AppUrl.ADMIN_URL + 'employee-attendance';
  }


  static FINAL_STUDENT_VERIFICATION(): string {
    return AppUrl.ADMIN_URL + 'final-verification';
  }

  static FINAL_STUDENT_VERIFICATION_EXCEL(transactionId: any): string {
    return AppUrl.ADMIN_URL + 'final-verification/' + transactionId;
  }

  static STUDENT_COUNT(): string {
    return AppUrl.ADMIN_URL + 'student-count';
  }

  static STUDENT_PERSONAL_INFO(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'student-personal-info/' + uniqueId;
  }

  static STUDENT_PHOTOGRAPH(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'student-personal-info-photograph/' + uniqueId;
  }

  static BDE_FEE_STRUCTURE(uniqueId: any): string {
    return (
      AppUrl.ADMIN_URL + 'bde-student-fee/' + uniqueId
    );
  }

  static STUDENT_GOVT_PHOTO_ID(uniqueId: any): string {
    return (
      AppUrl.ADMIN_URL + 'student-personal-info-govt-photo-id-proof/' + uniqueId
    );
  }

  static STUDENT_SIGNATURE(uniqueId: any): string {
    return (
      AppUrl.ADMIN_URL + 'student-personal-info-student-signature/' + uniqueId
    );
  }

  static STUDENT_ADDRESS_INFO(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'student-address/' + uniqueId;
  }

  static STUDENT_ACADEMIC_DETAIL(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'academic-details/' + uniqueId;
  }

  static STUDENT_TENTH_CERTIFICATE(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'academic-details-tenth-certificate/' + uniqueId;
  }

  static STUDENT_TWELTH_CERTIFICATE(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'academic-details-twelth-certificate/' + uniqueId;
  }

  static STUDENT_MORE_QUALIFICATION(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'more-qualification/' + uniqueId;
  }

  static STUDENT_REFERRAL(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'referral/' + uniqueId;
  }

  static STUDENT_PREVIOUS_CERTIFICATE(uniqueId: any): string {
    return (
      AppUrl.ADMIN_URL + 'more-qualification-previous-certificate/' + uniqueId
    );
  }

  static STUDENT_GRADUATION_CERTIFICATE(uniqueId: any): string {
    return (
      AppUrl.ADMIN_URL + 'more-qualification-graduation-certificate/' + uniqueId
    );
  }

  static STUDENT_POST_GRADUATION_CERTIFICATE(uniqueId: any): string {
    return (
      AppUrl.ADMIN_URL +
      'more-qualification-post-graduation-certificate/' +
      uniqueId
    );
  }

  static STUDENT_OTHER_QUALIFICATION_CERTIFICATE(uniqueId: any): string {
    return (
      AppUrl.ADMIN_URL +
      'more-qualification-other-qualification-certificate/' +
      uniqueId
    );
  }

  static STUDENT_REFERRAL_DOCUMENT(uniqueId: any): string {
    return (
      AppUrl.ADMIN_URL +
      'referral-document/' +
      uniqueId
    );
  }

  static STUDENT_COURSE_DETAIL(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'course-details/' + uniqueId;
  }

  static STUDENT_COURSE_FEE(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'course-fee/' + uniqueId;
  }

  static SUBJECT_SLM_ID(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'subject-slm/' + uniqueId;
  }

  static SUBJECT_NOTE_ID(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'subject-note/' + uniqueId;
  }

  static STUDENT_TRANSACTION(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'student-transaction/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'student-transaction';
    }
  }

  static STUDENT_ASSIGN_HOSTEL(uniqueId?: any): string {
    return AppUrl.ADMIN_URL + 'student-hostel-assign/' + uniqueId;
  }

  static STUDENT_ASSIGN_VEHICLE(uniqueId?: any): string {
    return AppUrl.ADMIN_URL + 'student-vehicle-assign/' + uniqueId;
  }

  static STUDENT_ENROLLMENT(): string {
    return AppUrl.ADMIN_URL + 'assign-enrollment';
  }

  static VERIFY_STUDENT(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'verify-student/' + uniqueId;
  }

  static VERIFY_STUDENT_SCHOLARSHIP(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'verify-student-scholarship/' + uniqueId;
  }

  static REJECT_STUDENT(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'reject-student/' + uniqueId;
  }

  static DOCUMENT_REQUIRED_STUDENT(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'student-document-required/' + uniqueId;
  }

  static STUDENT_RESULT(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'student-internal-result/' + uniqueId;
  }

  static ELIGIBLE_STUDENT(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'student-internal-result/' + uniqueId;
  }

  static STUDENT_ATTENDANCE(): string {
    return AppUrl.ADMIN_URL + 'student-attendance';
  }

  static STUDENT_ATTENDANCE_COUNT(): string {
    return AppUrl.ADMIN_URL + 'student-attendance-count';
  }

  static HOSTEL(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'hostel/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'hostel';
    }
  }

  static HOSTEL_COUNT(): string {
    return AppUrl.ADMIN_URL + 'hostel-count';
  }

  static HOSTEL_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'hostel-status/' + uniqueId;
  }

  static HOSTEL_ROOM(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'hostel-room/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'hostel-room';
    }
  }

  static HOSTEL_ROOM_COUNT(): string {
    return AppUrl.ADMIN_URL + 'hostel-room-count';
  }

  static HOSTEL_ROOM_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'hostel-room-status/' + uniqueId;
  }

  static HOSTEL_MENU(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'hostel-menu/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'hostel-menu';
    }
  }

  static HOSTEL_MENU_COUNT(): string {
    return AppUrl.ADMIN_URL + 'hostel-menu-count';
  }

  static HOSTEL_MENU_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'hostel-menu-status/' + uniqueId;
  }

  static VEHICLE(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'vehicle/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'vehicle';
    }
  }

  static VEHICLE_COUNT(): string {
    return AppUrl.ADMIN_URL + 'vehicle-count';
  }

  static VEHICLE_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'vehicle-status/' + uniqueId;
  }

  static VEHICLE_DRIVER(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'vehicle-driver/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'vehicle-driver';
    }
  }

  static VEHICLE_DRIVER_COUNT(): string {
    return AppUrl.ADMIN_URL + 'vehicle-driver-count';
  }

  static VEHICLE_DRIVER_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'vehicle-driver-status/' + uniqueId;
  }

  static VEHICLE_ROUTE(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'vehicle-route/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'vehicle-route';
    }
  }


  static ALUMNI(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'alumni/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'alumni';
    }
  }

  static ALUMNI_IMAGES(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'alumni-activity-images/' + uniqueId;
    }
  }

  static ALUMNI_COUNT(): string {
    return AppUrl.ADMIN_URL + 'alumni-count';
  }

  static ALUMNI_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'alumni-status/' + uniqueId;
  }

  static ALUMNI_ACTIVITY(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'alumni-activity/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'alumni-activity';
    }
  }

  static ALUMNI_ACTIVITY_IMAGE(uniqueId?: any): string {
    return AppUrl.ADMIN_URL + 'alumni-activity-images/' + uniqueId;
  }

  static ALUMNI_ACTIVITY_COUNT(): string {
    return AppUrl.ADMIN_URL + 'alumni-activity-count';
  }

  static ALUMNI_ACTIVITY_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'alumni-activity-status/' + uniqueId;
  }

  static ALLEGATION_REMARKS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'allegation-status/' + uniqueId;
  }

  static SUPPORT_TICKET(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'support-ticket/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'support-ticket';
    }
  }

  static SUPPORT_TICKET_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'support-ticket-status/' + uniqueId;
  }

  static SUPPORT_TICKET_IMAGE(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'support-ticket-image/' + uniqueId;
  }

  static STUDENT_SUPPORT_TICKET(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'support-ticket-student/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'support-ticket-student';
    }
  }

  static STUDENT_SUPPORT_TICKET_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'support-ticket-student-status/' + uniqueId;
  }

  static STUDENT_SUPPORT_TICKET_IMAGE(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'support-ticket-student-image/' + uniqueId;
  }

  static SEARCH_STUDENT_BY_ENROLLMENT_NUMBER(): string {
    return AppUrl.ADMIN_URL + 'allegation-student';
  }

  static ALLEGATIONS_UPLOADED_DOCS(): string {
    return AppUrl.ADMIN_URL + 'allegation-files';
  }

  static RECEIPT(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'receipt/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'receipt';
    }
  }

  static RECEIPT_TRANSACTION(): string {
    return AppUrl.ADMIN_URL + 'receipt-transactions';
  }

  static WALLET_TRANSACTION(): string {
    return AppUrl.ADMIN_URL + 'wallet-transactions';
  }

  static STUDENT_ASSIGNMENT(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'assignment/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'assignment';
    }
  }

  static STUDENT_ASSIGNMENT_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'assignment-status/' + uniqueId;
  }

  static HOLIDAY(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'holiday/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'holiday';
    }
  }

  static MISCELLANEOUS_CHARGE(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'miscellaneous/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'miscellaneous';
    }
  }

  static MISCELLANEOUS_CHARGE_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'miscellaneous-status/' + uniqueId;
  }

  static SCHOLARSHIP(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'scholarship/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'scholarship';
    }
  }

  static SCHOLARSHIP_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'scholarship-status/' + uniqueId;
  }

  static PERMISSION(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'permission/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'permission';
    }
  }

  static PERMISSION_COUNT(): string {
    return AppUrl.ADMIN_URL + 'permission-count';
  }

  static PERMISSION_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'permission-status/' + uniqueId;
  }

  static SUB_PERMISSION(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'sub-permission/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'sub-permission';
    }
  }

  static SUB_PERMISSION_COUNT(): string {
    return AppUrl.ADMIN_URL + 'sub-permission-count';
  }

  static SUB_PERMISSION_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'sub-permission-status/' + uniqueId;
  }

  static DESIGNATION_PERMISSION(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'designation-permission/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'designation-permission';
    }
  }

  static DESIGNATION_PERMISSION_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'designation-permission-status/' + uniqueId;
  }

  static HOME_COUNTS(): string {
    return AppUrl.ADMIN_URL + 'home-counts';
  }

  static GENERAL_COUNTS(): string {
    return AppUrl.ADMIN_URL + 'general-counts';
  }

  static EMPLOYEE_REPORT_COUNTS(): string {
    return AppUrl.ADMIN_URL + 'employee-counts';
  }

  static MODULE_REPORT_COUNTS(): string {
    return AppUrl.ADMIN_URL + 'module-counts';
  }

  static INVENTORY_REPORT_COUNTS(): string {
    return AppUrl.ADMIN_URL + 'inventory-counts';
  }

  static BOOK_REPORT_COUNTS(): string {
    return AppUrl.ADMIN_URL + 'book-counts';
  }

  static LIBRARY_REPORT_COUNTS(): string {
    return AppUrl.ADMIN_URL + 'library-counts';
  }

  static STUDENT_REPORT_COUNTS(): string {
    return AppUrl.ADMIN_URL + 'student-counts';
  }

  static ACCOUNT_REPORT_COUNTS(): string {
    return AppUrl.ADMIN_URL + 'account-counts';
  }

  static UTILITY_REPORT_COUNTS(): string {
    return AppUrl.ADMIN_URL + 'utility-counts';
  }

  static TICKET_REPORT_COUNTS(): string {
    return AppUrl.ADMIN_URL + 'ticket-counts';
  }

  static NOTIFICATION_REPORT_COUNTS(): string {
    return AppUrl.ADMIN_URL + 'notification-counts';
  }

  static GENERAL_REPORT(): string {
    return AppUrl.ADMIN_URL + 'general-reports';
  }

  static EMPLOYEE_REPORT(): string {
    return AppUrl.ADMIN_URL + 'employee-reports';
  }

  static MODULE_REPORT(): string {
    return AppUrl.ADMIN_URL + 'module-reports';
  }

  static INVENTORY_REPORT(): string {
    return AppUrl.ADMIN_URL + 'inventory-reports';
  }

  static BOOK_REPORT(): string {
    return AppUrl.ADMIN_URL + 'book-reports';
  }

  static LIBRARY_REPORT(): string {
    return AppUrl.ADMIN_URL + 'library-reports';
  }

  static STUDENT_REPORT(): string {
    return AppUrl.ADMIN_URL + 'student-reports';
  }

  static ACCOUNT_REPORT(): string {
    return AppUrl.ADMIN_URL + 'account-reports';
  }

  static UTILITY_REPORT(): string {
    return AppUrl.ADMIN_URL + 'utility-reports';
  }

  static SUPPORT_TICKET_REPORT(): string {
    return AppUrl.ADMIN_URL + 'ticket-reports';
  }

  static NOTIFICATION_REPORT(): string {
    return AppUrl.ADMIN_URL + 'notification-reports';
  }

  static NOTIFY(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'notify/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'notification';
  }

  static NOTIFY_COUNT(): string {
    return AppUrl.ADMIN_URL + 'notify-count';
  }

  static STUDENT_NOTIFY(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'notify/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'student-notification';
  }

  static STUDENT_NOTIFY_COUNT(): string {
    return AppUrl.ADMIN_URL + 'student-notify-count';
  }

  static EMPLOYEE_SUBJECT_MAPPING(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'employee-subject-mapping/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'employee-subject-mapping';
    }
  }

  static EMPLOYEE_SUBJECT_MAPPING_COUNT(): string {
    return AppUrl.ADMIN_URL + 'employee-subject-mapping-count';
  }

  static EMPLOYEE_SUBJECT_MAPPING_STATUS(unique_id: any): string {
    return AppUrl.ADMIN_URL + 'employee-subject-mapping-status/' + unique_id;
  }

  static DOCS(uniqueId?: any): string {
    if (uniqueId)
      return AppUrl.ADMIN_URL + 'student-docs/' + uniqueId;
    else
      return AppUrl.ADMIN_URL + 'student-docs';
  }

  static NO_DUES(): string {
    return AppUrl.ADMIN_URL + 'no-due-slip';
  }

  static NO_DUES_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'no-due-slip-status/' + uniqueId;
  }

  static PAYMENT_PROCESS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'student-docs-payment-process/' + uniqueId;
  }

  static PAYMENT_VALIDATE(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'student-docs-payment-validate/' + uniqueId;
  }

  static DOCUMENT_VALIDATE(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'student-docs-validate/' + uniqueId;
  }

  static PRINT_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'student-docs-print/' + uniqueId;
  }

  static CONSIGNMENT(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'student-docs-consignment/' + uniqueId;
  }

  static DELIVERY(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'student-docs-delivery/' + uniqueId;
  }

  static MEETING_APPROVAL_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'meeting-status/' + uniqueId;
  }

  static RESIGNATION(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'resignation/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'resignation';
  }

  static RESIGNATION_STATUS(uniqueId?: any): string {
    return AppUrl.ADMIN_URL + 'resignation-status/' + uniqueId;
  }

  static LEAVE(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'leave/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'leave';
  }

  static VISITOR_TOKEN(uniqueId: any): string {
    return environment.appUrl + 'meeting-by-id/' + uniqueId;
  }

  static BDE_STUDENT(uniqueId?: any): string {
    if (uniqueId) {
      return AppUrl.ADMIN_URL + 'bde-student/' + uniqueId;
    } else {
      return AppUrl.ADMIN_URL + 'bde-student';
    }
  }

  static BDE_STUDENT_RESULT(uniqueId?: any): string {
    return AppUrl.ADMIN_URL + 'bde-student-result/' + uniqueId;
  }

  static GET_BDE_RESULT(uniqueId?: any): string {
    return AppUrl.ADMIN_URL + 'student-result/' + uniqueId;
  }

  static SUPPLIERS(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'supplier/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'supplier';
  }

  static SUPPLIER_STATUS(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'supplier-status/' + uniqueId;
  }

  static LIBRARIES(uniqueId?: any): string {
    if (uniqueId) return AppUrl.ADMIN_URL + 'libraries/' + uniqueId;
    else return AppUrl.ADMIN_URL + 'libraries';
  }

  static LIBRARIES_STATUS(uniqueId?: any): string {
    return AppUrl.ADMIN_URL + 'libraries-status/' + uniqueId;
  }

  static SUB_TASK(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'task-subtask/' + uniqueId;
  }

  static SUB_TASK_ASSIGN(uniqueId: any): string {
    return AppUrl.ADMIN_URL + 'task-subtask-assign/' + uniqueId;
  }
}
